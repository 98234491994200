import React, {useEffect, useState, useRef, useCallback} from 'react';
import styles from './Form.module.css';
import { InputText } from 'primereact/inputtext';
import axios from "axios";
import {Avatar} from "primereact/avatar";
import {Sidebar} from "primereact/sidebar";
import imgClock from"./img/clock.png"
import imgMessage from"./img/message.png"
import imgSend from"./img/send.png"
import imgClose from"./img/close.png"
import {EventEmitter} from "../AppState";
import {Button} from "primereact/button";
import {confirmDialog} from "primereact/confirmdialog";
import {InputTextarea} from "primereact/inputtextarea";

function Form(props) {

    const load = useCallback(() => {
        services.load()
    }, []);

    const [messageContent, setMessageContent] = useState();
    const [name, setName] = useState();
    const [messages, setMessages] = useState([]);
    const messagesEndRef = useRef(null);
    const inputText = useRef(null);


    useEffect(() => {
        if(props.username != null && props.recipient != null) {
            services.load();
            EventEmitter.addEventListener('NEW_MESSAGE_CHATBOX', services.load)
        } else {
            EventEmitter.removeEventListener('NEW_MESSAGE_CHATBOX')
        }
    }, [props.username, props.recipient]);

    useEffect(() => {
        messagesEndRef.current?.scrollTo({ top: 999999, behavior: "smooth" })
    }, [messages]);

    const services = {
        send:() => {
            let body = {
                sender: props.username
                , recipient: props.recipient
                , content: messageContent
            }

            let url = window.location.protocol+"//"+window.location.host;
            url = url+'/module/chat/api/messages'
            axios.post(url, body).then(response => {
                setMessageContent("")
            })
                .catch(console.log)
        },
        sendName:() => {
            let body = {
                sender: props.username
                , recipient: props.recipient
                , name: name
            }

            let url = window.location.protocol+"//"+window.location.host;
            url = url+'/module/chat/api/messages/init'
            axios.post(url, body).then(response => {
                setName("")
            })
                .catch(console.log)
        },

        read:(id) => {
            let body = {
                sender: props.username
                , recipient: props.recipient
                , id: id
            }

            let url = window.location.protocol+"//"+window.location.host;
            url = url+'/module/chat/api/messages/read'
            axios.post(url, body).then(response => {
            })
                .catch(console.log)
        },

        delete:() => {

            confirmDialog({
                message: <div style={{padding: 10}}>Möchtest du die Unterhaltung wirklich löschen?</div>,
                header: 'Bestätigung',
                acceptLabel: 'ja',
                accept: () => {
                    let body = {
                        sender: props.username
                        , recipient: props.recipient
                    }

                    let url = window.location.protocol+"//"+window.location.host;
                    url = url+'/module/chat/api/messages/delete'
                    axios.post(url, body).then(response => {
                    })
                        .catch(console.log)
                },
                rejectLabel: 'nein',
                reject: () => null
            });


        },
        load:() => {
            let url = window.location.protocol+"//"+window.location.host;
            url = url+'/module/chat/api/messages/'+props.username+ '/' + props.recipient
            axios.get(url).then(response => {
                setMessages(response.data)
                var unreadList = []
                response.data.map(row => {
                    if(row.recipient == props.username && row.recipientHasRead == false) {
                        unreadList.push(row)
                    }
                })


                unreadList.map(message => {
                    services.read(message.id)
                })
            })
                .catch(console.log)

        },
        formatDate: (value) => {
            if(value == null) {
                return null
            } else if(value == false) {
                return null
            } else {
                return (
                    <span>
                        {value.slice(11, 16)}&nbsp;Uhr&nbsp;
                    </span>
                )
            }
        }
    }

    const Message = (props) => {
        return (
            <div className={props.message.sender === props.username ? styles.yourMessega : styles.otherMessega}>
                <div className={styles.messagaContent}>{props.message.content}</div>
                <div style={{fontSize: 14, marginBottom: '-10px'}}>
                    <div className={styles.timeContent}>
                        {services.formatDate(props.message.dateCreated)}
                        <img style={{width: '11px', marginBottom: -1}} src={imgClock}/>
                    </div>

                </div>
            </div>
        );
    }

    return (
    <Sidebar
        showCloseIcon={false}
        visible={props.recipient}
        position="bottom"
        onHide={() => props.onHide && props.onHide()}
        style={
            window.innerWidth < 600 ?
                {width: '90%', marginLeft: '5%', marginRight: '5%', height: '60vh', background: '#E6F4E9'}
                :
                {width: 400, height: '60vh', marginLeft: 'calc(100% - 480px)', background: '#E6F4E9'}
        }
        modal={false}
        closeIcon={null}
        header={() => (
            <div style={{width: '100%'}}>
                <table>
                    <tbody>
                    <tr>
                        <td>
                            {props.avatar && (
                                <img src={props.avatar} style={{height: 70, width: 70, marginLeft: 15, marginTop: 5}}/>
                            )}
                            {props.avatar == null && (
                                <Avatar
                                    label={props.recipientInitals}
                                    style={{backgroundColor: '#494949', color: '#FAC24C', height: 70, width: 70, marginLeft: 15}}
                                    shape="circle"/>
                            )}

                        </td>
                        <td style={{width: '100%'}}>
                            <img className={styles.chatHeaderMessageImg} src={imgMessage} />
                            <div className={styles.chatHeaderName}>{props.recipientName}</div>
                            <div className={styles.chatHeaderOnline}><i className="fa-solid fa-circle"></i> Online</div>
                            <div style={{height: 10}}></div>
                        </td>
                        <td style={{paddingRight: '15px'}}>
                            <div className={styles.closeBackground} onClick={() => props.onHide && props.onHide()}>
                                <img src={imgClose} style={{width: 17}}/>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>

            </div>
        )}>
        <div>

            {messages.length != 0 && (
                <div className={styles.chatConversation} ref={messagesEndRef}>
                    {messages.map(message => (
                        <Message message={message} username={props.username}/>
                    ))}
                </div>
            )}

            {messages.length == 0 && (
                <div className={styles.chatInitConversation}>

                    <center>
                        <img src={props.avatar} style={{height: 80, width: 80, marginLeft: 15, marginTop: -70}}/>
                    </center>
                    <div className={styles.chatInitConversationHeader}>Willkommen zu unserem</div>
                    <div className={styles.chatInitConversationHeaderSecond}>Live Chat!</div>
                    <div className={styles.chatInitConversationHeaderText}>Bitte geben Sie Ihren Namen ein um zu
                        starten
                    </div>

                    <div className={styles.chatInitConversationHeaderTextInputTextParent}>
                        <InputText
                            className={styles.chatInitConversationHeaderTextInputText}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    services.sendName()
                                }
                            }}/>
                    </div>
                    <div className={styles.chatInitConversationStartButton}
                         onClick={() => services.sendName()}>starten
                    </div>
                    <center>Reaktionszeit <img style={{width: '14px', marginBottom: -1}} src={imgClock}/> ca. 3 Minuten
                    </center>

                </div>
            )}
            {messages.length != 0 && (
                <table style={{width: '100%', borderSpacing: 0}} cellSpacing="0" cellPadding="0">
                    <tbody>
                    <tr>
                        <td>
                            <div className={styles.inputTextForm}>
                                <span className="p-input-icon-right" style={{width: '100%'}}>
                                    <i>
                                        <div className={styles.sendBackground}>
                                            <img src={imgSend} style={{width: 17}} onClick={() => {
                                                services.send()
                                            }}/>
                                        </div>
                                    </i>
                                    <InputTextarea
                                        ref={inputText}
                                        autoResize
                                        value={messageContent}
                                        style={{width: '100%'}}
                                        rows={2}
                                        maxLength={245}
                                        placeholder="Hier Frage stellen..."
                                        onChange={(e) => setMessageContent(e.target.value)}
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                services.send()
                                            }
                                        }}/>
                                </span>
                            </div>
                        </td>
                        {props.allowDelete && (
                            <td>
                                <Button
                                    style={{marginTop: 10}}
                                    icon="fa-solid fa-trash"
                                    severity="danger"
                                    onClick={() => services.delete()}/>
                            </td>
                        )}
                    </tr>
                    </tbody>
                </table>
            )}
        </div>
    </Sidebar>
    )
}

export default Form;