import styles from ".//Form.module.css";
import image1 from "./image1.jpg"
import image1anim from "./image1anim.png"
import image2 from "./image2.png"
import image2anim from "./image2anim.png"
import image3 from "./image3.png"
import image3anim from "./image3anim.png"
import React, {useEffect} from "react";
import {ReactCompareSlider, ReactCompareSliderImage} from "react-compare-slider";

function Form(props) {


    useEffect(() => {
        window.addEventListener('error', e => {
            if (e.message === 'ResizeObserver loop limit exceeded') {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div'
                );
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay'
                );
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        });
    }, []);

    return (
        <div  style={{background: 'var(--venmediaColor2)'}}>
            <div className="wrapper" style={{paddingBottom: 0}}>
                <div className="venmediaHeader" style={{textAlign: 'center', paddingBottom: 40}}>Nachanimation</div>
                <div className="grid">
                    <div className="col-4">
                        <ReactCompareSlider
                            style={{borderRadius: 30, boxShadow: '0px 14px 20px 5px rgba(0, 0, 0, 0.19)'}}
                            changePositionOnHover={true}
                            itemOne={<ReactCompareSliderImage src={image1} alt="image1 one" />}
                            itemTwo={<ReactCompareSliderImage src={image1anim} alt="image1 two" />}
                        />
                    </div>
                    <div className="col-4">
                        <ReactCompareSlider
                            style={{borderRadius: 30, boxShadow: '0px 14px 20px 5px rgba(0, 0, 0, 0.19)'}}
                            changePositionOnHover={true}
                            itemOne={<ReactCompareSliderImage src={image2} alt="image2 one" />}
                            itemTwo={<ReactCompareSliderImage src={image2anim} alt="image2 two" />}
                        />
                    </div>
                    <div className="col-4">
                        <ReactCompareSlider
                            style={{borderRadius: 30, boxShadow: '0px 14px 20px 5px rgba(0, 0, 0, 0.19)'}}
                            changePositionOnHover={true}
                            itemOne={<ReactCompareSliderImage src={image3} alt="image3 one" />}
                            itemTwo={<ReactCompareSliderImage src={image3anim} alt="image3 two" />}
                        />
                    </div>
                </div>
            </div>

        </div>
    )
}
export default Form