import {Bounce, Slide, Zoom} from "react-awesome-reveal";
import styles from "./Form.module.css";
import React, {useState} from "react";
import {AppApi} from "../AppApi";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {RadioButton} from "primereact/radiobutton";
import topImg from "./top.png";
import background from "./background.png";

function Form(props) {


    const [isFormPost, setIsFormPost] = useState(false);

    const [isTitleEmpty, setIsTitleEmpty] = useState(false);
    const [isCompanyEmpty, setIsCompanyEmpty] = useState(false);
    const [isNameEmpty, setIsNameEmpty] = useState(false);
    const [isEmailEmpty, setIsEmailEmpty] = useState(false);
    const [isTelephoneEmpty, setIsTelephoneEmpty] = useState(false);
    const [isSubjectEmpty, setIsSubjectEmpty] = useState(false);
    const [isMessageEmpty, setIsMessageEmpty] = useState(false);
    const [form, setForm] = useState({
        title: 'MR',
        name: null,
        company: null,
        email: null,
        telephone: null,
        subject: null,
        message: null
    });

    function isEmpty(s){
        return s == null || s.trim().length == 0
    }

    const actions = {
        post: () => {
            let sendEmail = true

            if(isEmpty(form.title)){
                setIsTitleEmpty(true)
            } else {
                setIsTitleEmpty(false)
            }

            if(isEmpty(form.name)){
                setIsNameEmpty(true)
                sendEmail = false
            } else {
                setIsNameEmpty(false)
            }

            if(isEmpty(form.company)){
                setIsCompanyEmpty(true)
            } else {
                setIsCompanyEmpty(false)
            }

            if(form.email == null || !form.email.includes("@") || !form.email.includes(".")) {
                setIsEmailEmpty(true)
                sendEmail = false
            } else {
                if(
                    form.email.toLowerCase().includes("@web")
                    || form.email.toLowerCase().includes("@icloud")
                    || form.email.toLowerCase().includes("@gmx")
                    || form.email.toLowerCase().includes("@hotmail")
                    || form.email.toLowerCase().includes("@outlook")
                    || form.email.toLowerCase().includes("@googlemail")
                    || form.email.toLowerCase().includes("@gmail")
                    || form.email.toLowerCase().includes("@freenet")
                    || form.email.toLowerCase().includes("@t-online.de")
                    || form.email.toLowerCase().includes("@mnet-mail.")
                    || form.email.toLowerCase().includes("@yahoo")
                ) {
                    setIsEmailEmpty(true)
                    sendEmail = false
                    alert("Bitte verwenden Sie für geschäftliche Anfragen Ihre Geschäfts-Mailadresse anstelle Ihrer privaten E-Mail.\n" +
                        "Falls Sie persönliche Anliegen haben, möchten wir Sie bitten, uns unter der Rufnummer 06171 951 80 86 anzurufen, damit wir Ihnen sofort eine Beratung anbieten können.")
                } else {
                    setIsEmailEmpty(false)
                }
            }

            if(isEmpty(form.telephone)){
                setIsTelephoneEmpty(true)
                sendEmail = false
            } else {
                setIsTelephoneEmpty(false)
            }

            if(isEmpty(form.subject)){
                setIsSubjectEmpty(true)
            } else {
                setIsSubjectEmpty(false)
            }

            if(isEmpty(form.message)){
                setIsMessageEmpty(true)
            } else {
                setIsMessageEmpty(false)
            }

            if(sendEmail) {
                let postForm = {
                    token: 'setLater',
                    title: form.title,
                    companyName: form.company,
                    name: form.name,
                    email: form.email,
                    telephone: form.telephone,
                    fields: [
                        {
                            type: "TEXT",
                            value: form.subject,
                            label: "Betreff"
                        },
                        {
                            type: "TEXT",
                            value: form.message,
                            label: "Nachricht"
                        }
                    ]
                }

                postForm.token = '4437306a-776d-4ea5-963e-139c0e1a0991'
                AppApi.pub.form.post(postForm)
                    .then(response => {
                        window.location.href = "/danke/kontakt"
                    })
                    .catch(console.log)
            }

        },
    }
    return (
        <div>
            <div className="wrapper">
                <div ref={props.childRef}>
                    <div className="grid">

                        <div className="col-12" style={{padding: 0}}>
                            <div style={{borderTopLeftRadius: '30px', borderTopRightRadius: '30px', background: 'var(--venmediaColor1)', color: '#FFFFFF', boxShadow:'0px 14px 20px 5px rgba(0, 0, 0, 0.19)' }}>

                                <Slide delay="20" triggerOnce>
                                    <div className="venmediaHeader" style={{paddingLeft: 15}}>Kontaktformular</div>
                                </Slide>
                            </div>
                        </div>
                        <div className="col-4" style={{padding: 0, background: 'var(--venmediaColor2)', boxShadow:'0px 14px 20px 5px rgba(0, 0, 0, 0.19)' }}>
                            <div>
                                <div style={{padding: 20}}>
                                    <div className="venmediaHeader2">Kontaktieren Sie uns!</div>
                                    <div style={{paddingTop: 20, color: 'var(--venmediaColor-2)'}}>
                                        Sie haben Fragen zu unserem Angebot oder ein anderes Anliegen? Kein Problem, senden Sie uns einfach eine E-Mail über das Kontakt-Formular.
                                    </div>

                                    <div style={{paddingTop: 10, color: '#FFFFFF'}}>
                                        <table>
                                            <tbody>

                                            <tr>
                                                <td><i className="pi pi-check" style={{fontWeight: 700, fontSize: 20, paddingLeft: 10, paddingRight: 10, color: '#FFFFFF'}}/></td>
                                                <td>Wir werden uns umgehend bei Ihnen melden!</td>
                                            </tr>
                                            <tr>
                                                <td><i className="pi pi-check" style={{fontWeight: 700, fontSize: 20, paddingLeft: 10, paddingRight: 10, color: '#FFFFFF'}}/></td>
                                                <td>Alle Fragen klären wir in einem Erstgespräch.</td>
                                            </tr>
                                            <tr>
                                                <td><i className="pi pi-check" style={{fontWeight: 700, fontSize: 20, paddingLeft: 10, paddingRight: 10, color: '#FFFFFF'}}/></td>
                                                <td>Sie erhalten ein unverbindliches Angebot.</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="venmediaHeader3" style={{paddingTop: 20}}>
                                        Wir freuen uns!
                                    </div>

                                </div>
                                <img src={background} style={{width: 300}}/>
                            </div>
                        </div>
                        <div className="col-8" style={{padding: 0, background: 'rgba(1, 217, 151, 0.3)', boxShadow:'0px 14px 20px 5px rgba(0, 0, 0, 0.19)'}}>
                            <div>
                                <div className="grid" style={{margin: 0, padding: 10}}>
                                    <div className="col-6" style={{padding: '0px 2px 0 2px'}}>
                                        <div style={{paddingTop: 10, fontWeight: 500, color: isTitleEmpty ? 'red' : null}}>Anrede:</div>
                                        <div>
                                            <RadioButton inputId="titleMale" value="MR" name="city" onChange={(event) => setForm({...form, title: event.value})} checked={form.title === 'MR'} />
                                            <label htmlFor="titleMale" style={{paddingLeft:5, paddingRight:20}}>Herr</label>
                                            <RadioButton inputId="titleFemale" value="MRS" name="city" onChange={(event) => setForm({...form, title: event.value})} checked={form.title === 'MRS'} />
                                            <label htmlFor="titleFemale" style={{paddingLeft:5, paddingRight:20}}>Frau</label>
                                            <RadioButton inputId="titleDivers" value="OTHER" name="city" onChange={(event) => setForm({...form, title: event.value})} checked={form.title === 'OTHER'} />
                                            <label htmlFor="titleDivers" style={{paddingLeft:5, paddingRight:20}}>Divers</label>
                                        </div>
                                    </div>

                                    <div className="col-6" style={{padding: '0px 2px 0 2px'}}>
                                    </div>
                                    <div className="col-6" style={{padding: '0px 2px 0 2px'}}>
                                        <div style={{paddingTop: 10, fontWeight: 500, color: isNameEmpty ? 'red' : null}}>Name:</div>
                                        <InputText
                                            className="p-inputtext-lg block"
                                            style={{width: '100%'}}
                                            value={form.name}
                                            placeholder="Name"
                                            onChange={(event) => setForm({...form, name: event.target.value})}/>
                                    </div>


                                    <div className="col-6" style={{padding: '0px 2px 0 2px'}}>
                                        <div style={{paddingTop: 10, fontWeight: 500, color: isCompanyEmpty ? 'red' : null}}>Firma:</div>
                                        <InputText
                                            className="p-inputtext-lg block"
                                            style={{width: '100%'}}
                                            value={form.company}
                                            placeholder="Firma"
                                            onChange={(event) => setForm({...form, company: event.target.value})}/>

                                    </div>
                                    <div className="col-6" style={{padding: '0px 2px 0 2px'}}>
                                        <div style={{paddingTop: 10, fontWeight: 500, color: isEmailEmpty ? 'red' : null}}>Email:</div>
                                        <InputText
                                            className="p-inputtext-lg block"
                                            style={{width: '100%'}}
                                            value={form.email}
                                            placeholder="Email"
                                            onChange={(event) => setForm({...form, email: event.target.value})}/>

                                    </div>
                                    <div className="col-6" style={{padding: '0px 2px 0 2px'}}>
                                        <div style={{paddingTop: 10, fontWeight: 500, color: isTelephoneEmpty ? 'red' : null}}>Telefon:</div>
                                        <InputText
                                            className="p-inputtext-lg block"
                                            style={{width: '100%'}}
                                            value={form.telephone}
                                            placeholder="Telefon"
                                            onChange={(event) => setForm({...form, telephone: event.target.value})}/>

                                    </div>
                                    <div className="col-12" style={{padding: '0px 2px 0 2px'}}>
                                        <div style={{paddingTop: 10, fontWeight: 500, color: isSubjectEmpty ? 'red' : null}}>Betreff:</div>
                                        <InputText
                                            className="p-inputtext-lg block"
                                            style={{width: '100%'}}
                                            value={form.subject}
                                            placeholder="Betreff"
                                            onChange={(event) => setForm({...form, subject: event.target.value})}/>


                                        <div style={{paddingTop: 10, fontWeight: 500, color: isMessageEmpty ? 'red' : null}}>Nachricht:</div>
                                        <InputTextarea
                                            rows={5}
                                            className="p-inputtext-lg block"
                                            style={{width: '100%'}}
                                            value={form.message}
                                            placeholder="Ihre Nachricht"
                                            onChange={(event) => setForm({...form, message: event.target.value})}/>
                                        <div style={{paddingTop: 20}}>
                                            VENmedia nutzt Ihre Angaben, um Sie hinsichtlich relevanter Inhalte & Produkte zu kontaktieren. Weitere Informationen finden Sie in unseren <a href="/datenschutz">Datenschutzbestimmungen</a>.
                                        </div>
                                        <div style={{paddingTop: 20, float: 'right'}}>
                                            <Bounce triggerOnce>
                                                <div
                                                    className={styles.button}
                                                    onClick={() => actions.post()}>
                                                    Kontaktformular senden
                                                </div>
                                            </Bounce>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    )
}
export default Form