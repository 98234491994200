import styles from '../Form.module.css';
import React from "react";
import img1 from "./img1.png";
import img2 from "./img2.png";
import KontaktForm from "../../kontakt/Form";
import {Bounce, Slide, Zoom} from "react-awesome-reveal";
import icon1 from "../../area2/icon1.png";
import icon2 from "../../area2/icon2.png";
import icon3 from "../../area2/icon3.png";
function Form(props) {
    return (
        <div>
            <div className={styles.top}>
                <div className="wrapper">
                    <div className="grid">
                        <div className="col-4">
                            <div style={{textAlign: 'center', width: '100%'}}>
                                <img src={img1} style={{width: '100%'}}/>
                            </div>
                        </div>
                        <div className="col-8">
                            <div>
                                <span className={styles.label}>Schulungsvideo</span>
                                <br/>
                                <br/>
                                <div>
                                    In einer Zeit, in der die Aufmerksamkeitsspanne immer kürzer wird und langweilige Texte kaum noch Beachtung finden, bieten wir Ihnen die zeitgemäße Lösung: fesselnde Schulungsvideos, die Ihre Zielgruppe begeistern und nachhaltig beeindrucken!
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="wrapper">

                    <center>
                        <div className={"venmediaHeader3 " + styles.itemHeader}>Warum sind unsere Schulungsvideos so außergewöhnlich effektiv?</div>
                        <div className={styles.itemContent}>
                            Wir verstehen, dass die Schulung Ihrer eigenen Belegschaft ein entscheidender Faktor für den Erfolg Ihres Unternehmens ist. Deshalb gehen wir mit unseren animierten Erklärvideos über das bloße Vermitteln von Informationen hinaus. Indem wir die Lerninhalte in eine emotionale und visuell ansprechende Welt einbetten, erreichen wir Ihre Mitarbeitenden auf einer tieferen Ebene.
                        </div>
                    </center>

                    <div className={styles.items}>
                        <div>
                            <div className={styles.item2}>
                                <Bounce delay="50" triggerOnce>
                                    <div>
                                        <div className={"venmediaHeader3 " + styles.itemHeader}>Identifikation und nachhaltiges Lernen:</div>
                                        <div className={styles.itemContent}>
                                            Die Kraft unserer kreativen Bildsprache liegt darin, dass sich Ihre Mitarbeiterinnen und Mitarbeiter viel leichter mit den vermittelten Themen identifizieren können. Das Erlebnis, das unsere Videos bieten, sorgt dafür, dass die Inhalte sich dauerhaft im Gedächtnis verankern und in den Arbeitsalltag integriert werden.
                                        </div>
                                    </div>
                                </Bounce>
                            </div>
                            <div className={styles.item2}>
                                <Bounce delay="100" triggerOnce>
                                    <div>
                                        <div className={"venmediaHeader3 " + styles.itemHeader}>Individuelle Anpassung an Ihre Bedürfnisse:</div>
                                        <div className={styles.itemContent}>
                                            Bei Venmedia wissen wir, dass jedes Unternehmen einzigartig ist. Deshalb erstellen wir jedes Schulungsvideo individuell auf Ihre spezifischen Anforderungen zugeschnitten. Wir nehmen Ihre Lager-, Büro- oder Anlagenräume und verwandeln sie in eine animierte Umgebung, die Ihren Mitarbeitenden vertraut ist und in der sie sich wohl fühlen. So schaffen wir die ideale Grundlage für eine effektive Wissensvermittlung.
                                        </div>
                                    </div>
                                </Bounce>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrapper">

                <div  style={{textAlign: 'center'}}>

                    <div className="venmediaHeader">Schulungsvideos Mit Venmedia</div>
                    <div>
                        Unser kreatives Team von Expertinnen und Experten steht Ihnen dabei zur Seite und entwickelt gemeinsam mit Ihnen ein Konzept, das Ihre Botschaft auf den Punkt bringt und Ihre Zuschauer fesselt.
                        <br/><br/>
                        Mit Venmedia setzen Sie nicht nur auf langjährige Erfahrung im Bereich der Videoproduktion, sondern auch auf eine partnerschaftliche Zusammenarbeit. Wir hören Ihnen genau zu, verstehen Ihre Ziele und entwickeln Lösungen, die Ihre Erwartungen übertreffen.
                        <br/><br/>
                        Bringen Sie frischen Wind in Ihre Schulungsmaßnahmen und steigern Sie die Effizienz Ihrer Mitarbeiterentwicklung mit unseren maßgeschneiderten Schulungsvideos. Ob für die Einführung neuer Prozesse, die Vermittlung von Fachwissen oder die Sensibilisierung für wichtige Themen - wir haben die passende Lösung für Sie.
                        <br/><br/>
                        Kontaktieren Sie uns noch heute, um mehr über unsere umfangreichen Dienstleistungen zu erfahren. Gemeinsam können wir Ihr individuelles Schulungsvideo entwickeln, das Ihre Mitarbeitenden nachhaltig begeistern und zu Höchstleistungen motivieren wird. Vertrauen Sie auf Venmedia - Ihre erste Wahl für animierte Erklärvideos und Schulungsvideos!
                    </div>

                </div>
            </div>

        </div>
    )
}
export default Form