import styles from './Form.module.css';

function Form(props) {

    return (

        <div style={{width: '100%', position: 'absolute'}}>
            <div className={styles.body}>
                <div className={styles.root} style={props.backgroundColor ? {backgroundColor: props.backgroundColor} : null}>
                </div>
            </div>
        </div>
    )
}
export default Form