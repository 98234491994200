import styles from "./Form.module.css";
import logo1 from "./logo1.png"
import logo2 from "./logo2.png"
import logo3 from "./logo3.png"
import logo4 from "./logo4.png"
import logo5 from "./logo5.png"
import logo6 from "./logo6.png"
import logo7 from "./logo7.png"
import logo8 from "./logo8.png"
import logo9 from "./logo9.png"
import logo10 from "./logo10.png"
import logo11 from "./logo11.png"
import logo12 from "./logo12.png"
import logo13 from "./logo13.png"
import logo14 from "./logo14.png"
import logo15 from "./logo15.png"
import logo16 from "./logo16.png"
import logo17 from "./logo17.png"
import logo18 from "./logo18.png"
import logo19 from "./logo19.png"
import logo20 from "./logo20.png"
import logo21 from "./logo21.png"
import logo22 from "./logo22.png"
import logo23 from "./logo23.png"
import logo24 from "./logo24.png"
import logo25 from "./logo25.png"
import logo26 from "./logo26.png"
import logo27 from "./logo27.png"
import logo28 from "./logo28.png"
import welleImg from "../area12/welleTop.svg"
import React, {useEffect, useState} from "react";
import {Slide, Zoom} from "react-awesome-reveal";

function Form(props) {
    const [time, setTime] = useState(1);

    useEffect(() => {
        const interval = setInterval(() => {
            if(time == 24){
                setTime(1);
            } else {
                setTime(time+1);
            }
        }, 1000)

        return () => {
            clearInterval(interval);
        }
    }, [time])

    return (
        <div>
            <div style={{background: 'rgba(255,255,255,0.8)'}}>
                <img src={welleImg} style={{width: '100%', marginBottom: -7}}/>
            </div>



            <div  style={{background: '#FFFFFF'}}>
                <div className="wrapper">
                    <div ref={props.childRef} style={{paddingBottom: 10}}>
                        <center>
                            <div className="venmediaHeader2" style={{marginTop: -40, paddingBottom: 20}}>Mehr als <span style={{color: 'var(--venmediaColor2)', fontSize: 48}}>1.000</span> glückliche Kunden</div>
                        </center>
                        <center>
                            <table style={{width: '100%'}}>
                                <tbody>
                                <tr>
                                    <td className={styles.tdLogo}>
                                        <Zoom>
                                            <img src={logo1} className={styles.logo}/>
                                        </Zoom>
                                    </td>
                                    <td className={styles.tdLogo}>
                                        <Zoom delay={200}>
                                            <img src={logo2} className={styles.logo}/>
                                        </Zoom>
                                    </td>
                                    <td className={styles.tdLogo}>
                                        <Zoom>
                                            <img src={logo3} className={styles.logo}/>
                                        </Zoom>
                                    </td>
                                    <td className={styles.tdLogo}>
                                        <Zoom delay={400}>
                                            <img src={logo4} className={styles.logo}/>
                                        </Zoom>
                                    </td>
                                    <td className={styles.tdLogo}>
                                        <Zoom>
                                            <img src={logo5} className={styles.logo}/>
                                        </Zoom>
                                    </td>
                                    <td className={styles.tdLogo}>
                                        <Zoom delay={200}>
                                            <img src={logo6} className={styles.logo}/>
                                        </Zoom>
                                    </td>
                                    <td className={styles.tdLogo}>
                                        <Zoom delay={400}>
                                            <img src={logo10} className={styles.logo}/>
                                        </Zoom>
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                            <table style={{width: '100%'}}>
                                <tbody>
                                <tr>
                                    <td className={styles.tdLogo}>
                                        <Zoom delay={400}>
                                            <img src={logo8} className={styles.logo}/>
                                        </Zoom>
                                    </td>
                                    <td className={styles.tdLogo}>
                                        <Zoom delay={200}>
                                            <img src={logo9} className={styles.logo}/>
                                        </Zoom>
                                    </td>
                                    <td className={styles.tdLogo}>
                                        <Zoom delay={600}>
                                            <img src={logo7} className={styles.logo}/>
                                        </Zoom>
                                    </td>
                                    <td className={styles.tdLogo}>
                                        <Zoom delay={200}>
                                            <img src={logo11} className={styles.logo}/>
                                        </Zoom>
                                    </td>
                                    <td className={styles.tdLogo}>
                                        <Zoom delay={400}>
                                            <img src={logo12} className={styles.logo}/>
                                        </Zoom>
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                            <table style={{width: '100%'}}>
                                <tbody>
                                <tr>
                                    <td className={styles.tdLogo}>
                                        <Zoom delay={400}>
                                            <img src={logo13} className={styles.logo}/>
                                        </Zoom>
                                    </td>
                                    <td className={styles.tdLogo}>
                                        <Zoom delay={600}>
                                            <img src={logo24} className={styles.logo}/>
                                        </Zoom>
                                    </td>
                                    <td className={styles.tdLogo}>
                                        <Zoom delay={600}>
                                            <img src={logo16} className={styles.logo}/>
                                        </Zoom>
                                    </td>
                                    <td className={styles.tdLogo}>
                                        <Zoom delay={400}>
                                            <img src={logo15} className={styles.logo}/>
                                        </Zoom>
                                    </td>
                                    <td className={styles.tdLogo}>
                                        <Zoom delay={400}>
                                            <img src={logo17} className={styles.logo}/>
                                        </Zoom>
                                    </td>
                                    <td className={styles.tdLogo}>
                                        <Zoom delay={600}>
                                            <img src={logo18} className={styles.logo}/>
                                        </Zoom>
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                            <table style={{width: '100%'}}>
                                <tbody>
                                <tr>
                                    <td className={styles.tdLogo}>
                                        <Zoom delay={600}>
                                            <img src={logo19} className={styles.logo}/>
                                        </Zoom>
                                    </td>
                                    <td className={styles.tdLogo}>
                                        <Zoom delay={800}>
                                            <img src={logo20} className={styles.logo}/>
                                        </Zoom>
                                    </td>
                                    <td className={styles.tdLogo}>
                                        <Zoom delay={600}>
                                            <img src={logo21} className={styles.logo}/>
                                        </Zoom>
                                    </td>
                                    <td className={styles.tdLogo}>
                                        <Zoom delay={800}>
                                            <img src={logo22} className={styles.logo}/>
                                        </Zoom>
                                    </td>
                                    <td className={styles.tdLogo}>
                                        <Zoom delay={600}>
                                            <img src={logo23} className={styles.logo}/>
                                        </Zoom>
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                            <table style={{width: '100%', paddingLeft: 20, paddingRight: 20}}>
                                <tbody>
                                <tr>
                                    <td className={styles.tdLogo}>
                                        <Zoom delay={1000}>
                                            <img src={logo25} className={styles.logo}/>
                                        </Zoom>
                                    </td>
                                    <td className={styles.tdLogo}>
                                        <Zoom delay={800}>
                                            <img src={logo26} className={styles.logo}/>
                                        </Zoom>
                                    </td>
                                    <td className={styles.tdLogo}>
                                        <Zoom delay={1000}>
                                            <img src={logo28} className={styles.logo}/>
                                        </Zoom>
                                    </td>
                                    <td className={styles.tdLogo}>
                                        <Zoom delay={1200}>
                                            <img src={logo27} className={styles.logo}/>
                                        </Zoom>
                                    </td>
                                    <td className={styles.tdLogo}>
                                        <Zoom delay={800}>
                                            <img src={logo14} className={styles.logo}/>
                                        </Zoom>
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                        </center>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default Form