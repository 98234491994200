import {Bounce, Fade, Roll, Slide, Zoom} from "react-awesome-reveal";
import styles from ".//Form.module.css";
import React, {useState} from "react";
import PlanetAnimation from "../ui/eeplanetanimation/Form";
import {InputText} from "primereact/inputtext";
import {Slider} from "primereact/slider";
import {Button} from "primereact/button";

import EtwasErklaerenImg from "./Erklären.png"
import AufmerksamkeitImg from "./Aufmerksamkeit.png"
import SchulungsvideoImg from "./Schulungsvideo.png"
import EtwasVerkaufenImg from "./Verkaufen.png"
import ProduktvorstellenImg from "./Produktvorstellen.png"
import WebseiteImg from "./Webseite.png"
import MitarbeiterImg from "./Mitarbeiter.png"
import SonsigesImg from "./Sonsiges.png"
import DauerImg from "./Dauer.png"
import {AppApi} from "../AppApi";
import {InputTextarea} from "primereact/inputtextarea";
import welleImg from "../area6/welleTop.svg";

function Item(props) {
    return (
        <React.Fragment>
            {!props.selected && props.enteredItemIndex == props.index && (<div className={styles.step1ItemState}><i className="pi pi-check"></i></div>)}
            {props.selected && (<div className={styles.step1ItemState}><i className="pi pi-check"></i></div>)}
            <div
                className={styles.step1Item}
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
                onClick={props.onClick}>
                <table>
                    <tbody>
                    <tr>
                        <td><img style={{width: 30, marginLeft: 10, marginRight: 10}} src={props.image}/></td>
                        <td>{props.children}</td>
                    </tr>
                    </tbody>
                </table>

            </div>
        </React.Fragment>
    );
}

function Form(props) {


    const [step1MouseEnterItem, setStep1MouseEnterItem] = useState();

    const [isNameEmpty, setIsNameEmpty] = useState(false);
    const [isEmailEmpty, setIsEmailEmpty] = useState(false);
    const [isTelephoneEmpty, setIsTelephoneEmpty] = useState(false);

    const [page, setPage] = useState(0);

    const [form, setForm] = useState({
        erklaerEtwas: false,
        aufmerksamkeitGenerieren: false,
        schulungsvideo: false,
        etwasVerkaufen: false,
        produktVorstellen: false,
        webSeiteToolVorstellen: false,
        mitarbeiterGewinnen: false,
        sonstiges: false,
        length: 30,
        count: 1,
        name: null,
        email: null,
        telephone: null,
        message: null
    });


    const actions = {
        post: () => {
            let sendEmail = true
            if(form.name == null) {
                setIsNameEmpty(true)
                sendEmail = false
            } else {
                setIsNameEmpty(false)
            }

            if(form.email == null || !form.email.includes("@") || !form.email.includes(".")) {
                setIsEmailEmpty(true)
                sendEmail = false
            } else {
                if(
                    form.email.toLowerCase().includes("@web")
                    || form.email.toLowerCase().includes("@icloud")
                    || form.email.toLowerCase().includes("@gmx")
                    || form.email.toLowerCase().includes("@hotmail")
                    || form.email.toLowerCase().includes("@outlook")
                    || form.email.toLowerCase().includes("@googlemail")
                    || form.email.toLowerCase().includes("@gmail")
                    || form.email.toLowerCase().includes("@freenet")
                    || form.email.toLowerCase().includes("@t-online.de")
                    || form.email.toLowerCase().includes("@mnet-mail.")
                    || form.email.toLowerCase().includes("@yahoo")
                ) {
                    setIsEmailEmpty(true)
                    sendEmail = false
                    alert("Bitte verwenden Sie für geschäftliche Anfragen Ihre Geschäfts-Mailadresse anstelle Ihrer privaten E-Mail.\n" +
                        "Falls Sie persönliche Anliegen haben, möchten wir Sie bitten, uns unter der Rufnummer 06171 951 80 86 anzurufen, damit wir Ihnen sofort eine Beratung anbieten können.")
                } else {
                    setIsEmailEmpty(false)
                }
            }

            if(form.telephone == null) {
                setIsTelephoneEmpty(true)
                sendEmail = false
            } else {
                setIsTelephoneEmpty(false)
            }

            if(sendEmail) {

                let postForm = {
                    token: '10c30474-5de5-4623-9d14-6f5ea5b832e9',
                    companyName: null,
                    firstName: null,
                    name: form.name,
                    email: form.email,
                    telephone: form.telephone,
                    fields: [
                        {
                            type: "TEXT",
                            value: form.message,
                            label: "Nachricht"
                        },
                        {
                            type: "TEXT",
                            value: form.count,
                            label: "Anzahl"
                        },
                        {
                            type: "TEXT",
                            value: form.length,
                            label: "Länge"
                        },
                        {
                            type: "TEXT",
                            value: form.mitarbeiterGewinnen,
                            label: "Mitarbeiter Gewinnen"
                        },
                        {
                            type: "TEXT",
                            value: form.webSeiteToolVorstellen,
                            label: "Webseite Tool Vorstellen"
                        },
                        {
                            type: "TEXT",
                            value: form.produktVorstellen,
                            label: "Produkt Vorstellen"
                        },
                        {
                            type: "TEXT",
                            value: form.etwasVerkaufen,
                            label: "Etwas Verkaufen"
                        },
                        {
                            type: "TEXT",
                            value: form.schulungsvideo,
                            label: "Schulungsvideo"
                        },
                        {
                            type: "TEXT",
                            value: form.aufmerksamkeitGenerieren,
                            label: "Aufmerksamkeit Generieren"
                        },
                        {
                            type: "TEXT",
                            value: form.erklaerEtwas,
                            label: "Erklaer etwas"
                        }
                    ]
                }

                AppApi.pub.form.post(postForm)
                    .then(response => {
                        window.location.href = "/danke/konfigurator"
                    })
                    .catch(console.log)
            }
        },
    }

    const charts =[
        (<div>
            <center>
                <div className="venmediaHeader3">Welche Ziele verfolgen Sie mit Ihrem Erklärvideo?</div>
            </center>

            <br/>
            <br/>
            <div className="grid">
                <div className="col-6">
                    <Item
                        image={WebseiteImg}
                        index={6}
                        selected={form.webSeiteToolVorstellen}
                        enteredItemIndex={step1MouseEnterItem}
                        onMouseEnter={() => setStep1MouseEnterItem(6)}
                        onMouseLeave={() => setStep1MouseEnterItem(0)}
                        onClick={() => setForm({...form, webSeiteToolVorstellen: !form.webSeiteToolVorstellen})}>
                        Webseite / Tool vorstellen
                    </Item>
                </div>
                <div className="col-6">
                    <Item
                        image={AufmerksamkeitImg}
                        index={2}
                        selected={form.aufmerksamkeitGenerieren}
                        enteredItemIndex={step1MouseEnterItem}
                        onMouseEnter={() => setStep1MouseEnterItem(2)}
                        onMouseLeave={() => setStep1MouseEnterItem(0)}
                        onClick={() => setForm({...form, aufmerksamkeitGenerieren: !form.aufmerksamkeitGenerieren})}>
                        Aufmerksamkeit generieren
                    </Item>
                </div>
                <div className="col-6">
                    <Item
                        image={SchulungsvideoImg}
                        index={3}
                        selected={form.schulungsvideo}
                        enteredItemIndex={step1MouseEnterItem}
                        onMouseEnter={() => setStep1MouseEnterItem(3)}
                        onMouseLeave={() => setStep1MouseEnterItem(0)}
                        onClick={() => setForm({...form, schulungsvideo: !form.schulungsvideo})}>
                        Schulungsvideo
                    </Item>
                </div>
                <div className="col-6">
                    <Item
                        image={EtwasVerkaufenImg}
                        index={4}
                        selected={form.etwasVerkaufen}
                        enteredItemIndex={step1MouseEnterItem}
                        onMouseEnter={() => setStep1MouseEnterItem(4)}
                        onMouseLeave={() => setStep1MouseEnterItem(0)}
                        onClick={() => setForm({...form, etwasVerkaufen: !form.etwasVerkaufen})}>
                        Etwas verkaufen
                    </Item>
                </div>
                <div className="col-6">
                    <Item
                        image={ProduktvorstellenImg}
                        index={5}
                        selected={form.produktVorstellen}
                        enteredItemIndex={step1MouseEnterItem}
                        onMouseEnter={() => setStep1MouseEnterItem(5)}
                        onMouseLeave={() => setStep1MouseEnterItem(0)}
                        onClick={() => setForm({...form, produktVorstellen: !form.produktVorstellen})}>
                        Produkt vorstellen
                    </Item>
                </div>
                <div className="col-6">
                    <Item
                        image={EtwasErklaerenImg}
                        index={1}
                        selected={form.erklaerEtwas}
                        enteredItemIndex={step1MouseEnterItem}
                        onMouseEnter={() => setStep1MouseEnterItem(1)}
                        onMouseLeave={() => setStep1MouseEnterItem(0)}
                        onClick={() => setForm({...form, erklaerEtwas: !form.erklaerEtwas})}>
                        Etwas erklären
                    </Item>
                </div>
                <div className="col-6">
                    <Item
                        image={MitarbeiterImg}
                        index={7}
                        selected={form.mitarbeiterGewinnen}
                        enteredItemIndex={step1MouseEnterItem}
                        onMouseEnter={() => setStep1MouseEnterItem(7)}
                        onMouseLeave={() => setStep1MouseEnterItem(0)}
                        onClick={() => setForm({...form, mitarbeiterGewinnen: !form.mitarbeiterGewinnen})}>
                        Mitarbeiter:innen gewinnen
                    </Item>
                </div>
                <div className="col-6">
                    <Item
                        image={SonsigesImg}
                        index={8}
                        selected={form.sonstiges}
                        enteredItemIndex={step1MouseEnterItem}
                        onMouseEnter={() => setStep1MouseEnterItem(8)}
                        onMouseLeave={() => setStep1MouseEnterItem(0)}
                        onClick={() => setForm({...form, sonstiges: !form.sonstiges})}>
                        Sonstiges
                    </Item>
                </div>
            </div>

            <div className={styles.step1Tooltip}>
                {step1MouseEnterItem != 0 && (
                    <table>
                        <tbody>
                        <tr>
                            <td style={{verticalAlign: 'top', padding: 10}}><i className="pi pi-info-circle" style={{fontSize: 24}}/></td>
                            <td>
                                <div>
                                    {step1MouseEnterItem == 1 && 'Ein Erklärvideo ist ein anschaulicher Weg, um komplexe Sachverhalte einfach und verständlich darzustellen.'}
                                    {step1MouseEnterItem == 2 && 'Ein qualitativ hochwertiges Video setzt Ihre Marke, Ihre Leistungen oder Ihr Unternehmen so authentisch wie möglich in Szene. Wir gehen noch einen Schritt weiter und entwickeln auf dieser Basis eine Strategie für die Generierung von Aufmerksamkeit.'}
                                    {step1MouseEnterItem == 3 && 'Sie wollen eine Video-Reihe für eine Schulung erstellen? Mit einem Erklärvideo bringen Sie die Inhalte verständlich und interessant auf den Punkt.'}
                                    {step1MouseEnterItem == 4 && 'Mit einer animierten Geschichte verpacken Sie Ihre Produkte und Dienstleistungen in ansprechendes Marketing.'}
                                    {step1MouseEnterItem == 5 && 'Mit geeigneten Animationen und Bildern macht ein neues Produkt wirkungsvoll auf sich aufmerksam. Mit Knowhow und Leidenschaft setzen wir die Eigenschaften Ihrer Neuerscheinungen zielgerichtet in den Fokus.'}
                                    {step1MouseEnterItem == 6 && 'Wir erstellen für Sie ein Video-Konzept, das die Funktionen, Eigenschaften und Möglichkeiten Ihrer Tools anschaulich und verständlich hervorhebt. '}
                                    {step1MouseEnterItem == 7 && 'Mit einem professionell konzipierten Erklärvideo schaffen Sie Authentizität. Auf diese Weise erhalten Sie Verstärkung, die zu Ihrem Unternehmen passt. '}
                                    {step1MouseEnterItem == 8 && 'Sie haben andere Ziele und möchten diese mit einem hochwertigen Erklärvideo erreichen? Lassen Sie und gerne an Ihren Ideen teilhaben.'}
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                )}
            </div>

        </div>),
        (<div>
            <center>
                <div className="venmediaHeader3">Welche länge soll ihr Erklärvideo haben?</div>
            </center>

            <br/>
            <br/>

            <center>
                <img style={{width: 90, marginLeft: 10, marginRight: 10}} src={DauerImg}/>
            </center>
            <br/>
            Die typische Länge eines Erklärvideos beträgt zwischen 30-90 Sekunden - Wenn Sie sich nicht sicher sind, können wir die Laufzeit gemeinsam in einem Beratungsgespräch klären.

            <br/>
            <br/>
            <center>
                <b>{form.length <= 240 ? form.length + ' Sek' : 'länger als 4 Minuten'}</b><br/>
            </center>

            <div style={{padding: 20}}>
                <table style={{width: '100%'}} cellPadding={0} cellSpacing={0}>
                    <tbody>
                    <tr>
                        <td style={{borderLeft: '1px solid #000000', width: '12.5%', height: 30}}/>
                        <td style={{borderLeft: '1px solid #000000', width: '12.5%'}}/>
                        <td style={{borderLeft: '1px solid #000000', width: '12.5%'}}/>
                        <td style={{borderLeft: '1px solid #000000', width: '12.5%'}}/>
                        <td style={{borderLeft: '1px solid #000000', width: '12.5%'}}/>
                        <td style={{borderLeft: '1px solid #000000', width: '12.5%'}}/>
                        <td style={{borderLeft: '1px solid #000000', width: '12.5%'}}/>
                        <td style={{borderLeft: '1px solid #000000', borderRight: '1px solid #000000', width: '12.5%'}}/>
                    </tr>
                    </tbody>
                </table>
                <Slider style={{marginTop: -10}} value={form.length} onChange={(e) => setForm({...form, length: e.value})} step={30} min={30} max={270}/>
            </div>

        </div>),
        (<div>
            <center>
                <div className="venmediaHeader3">Wie viele Erklärvideos benötigen Sie?</div>
                <br/>
                <br/>
                <br/>
                <table>
                    <tbody>
                    <tr>
                        <td><Button style={{padding: 30}} disabled={form.count < 2} icon="pi pi-minus" className="p-button-rounded p-button-danger" onClick={() => setForm({...form, count: form.count - 1})}/></td>
                        <td><span style={{fontSize: 120, padding: 30, color: '#444444'}}>{form.count}</span></td>
                        <td><Button style={{padding: 30}} icon="pi pi-plus" className="p-button-rounded p-button-success" onClick={() => setForm({...form, count: form.count + 1})}/></td>
                    </tr>
                    </tbody>
                </table>
            </center>


            <br/>
        </div>),
        (<div>
            <center>
                <div className="venmediaHeader3">Wie können wir Sie erreichen?</div>
            </center>

            <div style={{paddingTop: 10, color: isNameEmpty ? 'red' : null}}>Name:</div>
            <InputText
                className="p-inputtext-lg block"
                style={{width: '100%'}}
                value={form.name}
                placeholder="Name"
                onChange={(event) => setForm({...form, name: event.target.value})}/>

            <div style={{paddingTop: 10, color: isEmailEmpty ? 'red' : null}}>Email:</div>
            <InputText
                className="p-inputtext-lg block"
                style={{width: '100%'}}
                value={form.email}
                placeholder="Email"
                onChange={(event) => setForm({...form, email: event.target.value})}/>

            <div style={{paddingTop: 10, color: isTelephoneEmpty ? 'red' : null}}>Telefon:</div>
            <InputText
                className="p-inputtext-lg block"
                style={{width: '100%'}}
                value={form.telephone}
                placeholder="Telefon"
                onChange={(event) => setForm({...form, telephone: event.target.value})}/>

            <div style={{paddingTop: 10}}>Nachricht:</div>
            <InputTextarea
                rows={3}
                className="p-inputtext-lg block"
                style={{width: '100%'}}
                value={form.message}
                placeholder="Ihre Nachricht"
                onChange={(event) => setForm({...form, message: event.target.value})}/>

        </div>)]


    return (
        <div>
            <div style={{background: 'rgba(255,255,255,0.8)'}}>
                <img src={welleImg} style={{width: '100%', marginBottom: -7}}/>
            </div>
            <div style={{background: 'var(--venmediaColor2)'}}>
                <div style={{width: '100%', position: 'absolute'}}>
                    <center>
                        <Bounce>
                            <div style={{borderRadius: 999, marginTop: 30, height: '6vw', width: '6vw', background: '#4BA377', boxShadow: '0px 14px 20px 5px rgba(0, 0, 0, 0.19)'}}/>
                        </Bounce>
                    </center>
                </div>
                <div style={{width: '100%', position: 'absolute'}}>
                    <center>
                        <div style={{borderRadius: 999, marginTop: 100, marginLeft: '30vw', height: '3vw', width: '3vw', background: '#176b45', boxShadow: '0px 14px 20px 5px rgba(0, 0, 0, 0.19)'}}/>
                    </center>
                </div>
                <div style={{width: '100%', position: 'absolute'}}>
                    <center>
                        <div style={{borderRadius: 999, marginTop: 50, marginLeft: '-30vw', height: '3vw', width: '3vw', background: '#28a16b', boxShadow: '0px 14px 20px 5px rgba(0, 0, 0, 0.19)'}}/>
                    </center>
                </div>
                <PlanetAnimation backgroundColor="#28a16b"/>
                <div className="wrapper" style={{paddingBottom: '10px'}}>
                    <center>
                        <Slide delay="20" triggerOnce>
                            <div className="venmediaHeader">Nichts passendes dabei?</div>
                            <br/>
                            <div className="venmediaHeader3">Nutzen Sie unseren Konfigurator und erhalten Sie ein persönliches Angebot.</div>
                        </Slide>
                    </center>
                </div>
            </div>

            <div style={{background: 'linear-gradient(180deg, rgba(1,217,151,1) 0%, rgba(240,240,240,0) 35%)'}}>
                <div className="wrapper" style={{paddingBottom: 0}}>
                    <div ref={props.childRef}>
                        <center>
                            <div style={{maxWidth: 900}}>
                                <table style={{width: '100%'}} cellPadding={0} cellSpacing={0}>
                                    <tbody>
                                    <tr style={{height: 470}}>
                                        <td className={styles.leftForm}>
                                            <Slide direction="left" triggerOnce>
                                                <Fade>
                                                    <div className={styles.leftFormContent}>
                                                        {charts[page]}
                                                    </div>
                                                </Fade>
                                            </Slide>
                                        </td>
                                        <td className={styles.rightForm}>
                                            <Slide direction="right" duration={1500}  triggerOnce>
                                                <Fade duration={1500}>
                                                    <div className={styles.rightFormContent}>
                                                        <table>
                                                            <tbody>
                                                            {page >= 0 && (
                                                                <tr>
                                                                    <td style={{verticalAlign: 'top'}}><i style={{fontSize: 18, fontWeight: 600, marginTop: 8, marginRight: 6, color: 'var(--venmediaColor2)'}} className="pi pi-check"/></td>
                                                                    <td>
                                                                        <span className={styles.rightFormHeader} onClick={() => setPage(0)}>Ziel</span>
                                                                        {form.erklaerEtwas && (<Bounce triggerOnce><div className={styles.rightFormItem}>• Etwas erklären</div></Bounce>)}
                                                                        {form.aufmerksamkeitGenerieren && (<Bounce triggerOnce><div className={styles.rightFormItem}>• Aufmerksamkeit generieren</div></Bounce>)}
                                                                        {form.schulungsvideo && (<Bounce triggerOnce><div className={styles.rightFormItem}>• Schulungsvideo</div></Bounce>)}
                                                                        {form.etwasVerkaufen && (<Bounce triggerOnce><div className={styles.rightFormItem}>• Etwas verkaufen</div></Bounce>)}
                                                                        {form.produktVorstellen && (<Bounce triggerOnce><div className={styles.rightFormItem}>• Produkt vorstellen</div></Bounce>)}
                                                                        {form.webSeiteToolVorstellen && (<Bounce triggerOnce><div className={styles.rightFormItem}>• Webseite / Tool vorstellen</div></Bounce>)}
                                                                        {form.mitarbeiterGewinnen && (<Bounce triggerOnce><div className={styles.rightFormItem}>• Mitarbeiter*innen gewinnen</div></Bounce>)}
                                                                        {form.sonstiges && (<Bounce triggerOnce><div className={styles.rightFormItem}>• Sonstiges</div></Bounce>)}
                                                                    </td>
                                                                </tr>
                                                            )}
                                                            {page >= 1 && (
                                                                <tr>
                                                                    <td style={{verticalAlign: 'top'}}><i style={{fontSize: 18, fontWeight: 600, marginTop: 8, marginRight: 6, color: 'var(--venmediaColor2)'}} className="pi pi-check"/></td>
                                                                    <td>
                                                                        <span className={styles.rightFormHeader} onClick={() => setPage(1)}>Länge</span>
                                                                        {form.length != 0 && (<Bounce><div className={styles.rightFormItem}>{form.length <= 240 ? form.length + ' Sek' : 'länger als 4 Minuten'}</div></Bounce>)}

                                                                    </td>
                                                                </tr>
                                                            )}
                                                            {page >= 2 && (
                                                                <tr>
                                                                    <td style={{verticalAlign: 'top'}}><i style={{fontSize: 18, fontWeight: 600, marginTop: 8, marginRight: 6, color: 'var(--venmediaColor2)'}} className="pi pi-check"/></td>
                                                                    <td>
                                                                        <span className={styles.rightFormHeader} onClick={() => setPage(2)}>Anzahl</span>
                                                                        {form.count && (<Bounce><div className={styles.rightFormItem}>{form.count}</div></Bounce>)}
                                                                    </td>

                                                                </tr>
                                                            )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </Fade>
                                            </Slide>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={styles.leftFormBottom}>
                                            <div>
                                                {page > 0 && (<Button style={{float: 'left', background: 'var(--venmediaColor1)'}} icon="pi pi-chevron-left" onClick={() => setPage(page - 1)}/>)}
                                                {page < 3 && (<Button style={{float: 'right', background: 'var(--venmediaColor1)'}} icon="pi pi-chevron-right" onClick={() => setPage(page +1)}/>)}
                                                {page == 3 && (<Button style={{float: 'right', background: 'var(--venmediaColor1)'}} label="senden" onClick={() => actions.post()}/>)}
                                            </div>
                                        </td>
                                        <td className={styles.rightFormBottom}></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </center>
                    </div>
                </div>
            </div>


        </div>
    )
}
export default Form