
function Form(props) {

    return (
        <div className="wrapper">
            <div ref={props.childRef}>
                <div className="in">
                    <h1>Datenschutzerklärung</h1>
                    <h2>Einleitung</h2><br/>
                    <p>Mit der folgenden Datenschutzerklärung möchten wir Sie darüber aufklären, welche Arten Ihrer
                        personenbezogenen Daten (nachfolgend auch kurz als "Daten“ bezeichnet) wir zu welchen Zwecken und in
                        welchem Umfang im Rahmen der Bereitstellung unserer Applikation verarbeiten.</p><br/>
                    <p>Die verwendeten Begriffe sind nicht geschlechtsspezifisch.</p><br/>
                    <p>Stand: 29. Juli 2020</p><h2>Inhaltsübersicht</h2>
                    <ul className="index">
                        <li><a className="index-link" href="#m1870">Einleitung</a></li>
                        <li><a className="index-link" href="#m3">Verantwortlicher</a></li>
                        <li><a className="index-link" href="#mOverview">Übersicht der Verarbeitungen</a></li>
                        <li><a className="index-link" href="#m13">Maßgebliche Rechtsgrundlagen</a></li>
                        <li><a className="index-link" href="#m27">Sicherheitsmaßnahmen</a></li>
                        <li><a className="index-link" href="#m25">Übermittlung und Offenbarung von personenbezogenen
                            Daten</a></li>
                        <li><a className="index-link" href="#m24">Datenverarbeitung in Drittländern</a></li>
                        <li><a className="index-link" href="#m134">Einsatz von Cookies</a></li>
                        <li><a className="index-link" href="#m317">Kommerzielle und geschäftliche Leistungen</a></li>
                        <li><a className="index-link" href="#m225">Bereitstellung des Onlineangebotes und Webhosting</a>
                        </li>
                        <li><a className="index-link" href="#m469">Besondere Hinweise zu Applikationen (Apps)</a></li>
                        <li><a className="index-link" href="#m182">Kontaktaufnahme</a></li>
                        <li><a className="index-link" href="#m29">Cloud-Dienste</a></li>
                        <li><a className="index-link" href="#m17">Newsletter und elektronische Benachrichtigungen</a></li>
                        <li><a className="index-link" href="#m638">Werbliche Kommunikation via E-Mail, Post, Fax oder
                            Telefon</a></li>
                        <li><a className="index-link" href="#m263">Webanalyse, Monitoring und Optimierung</a></li>
                        <li><a className="index-link" href="#m264">Onlinemarketing</a></li>
                        <li><a className="index-link" href="#m136">Präsenzen in sozialen Netzwerken (Social Media)</a></li>
                        <li><a className="index-link" href="#m328">Plugins und eingebettete Funktionen sowie Inhalte</a>
                        </li>
                        <li><a className="index-link" href="#m12">Löschung von Daten</a></li>
                        <li><a className="index-link" href="#m15">Änderung und Aktualisierung der Datenschutzerklärung</a>
                        </li>
                        <li><a className="index-link" href="#m10">Rechte der betroffenen Personen</a></li>
                        <li><a className="index-link" href="#m42">Begriffsdefinitionen</a></li>
                    </ul>
                    <h2 id="m3">Verantwortlicher</h2><p>Venmedia<br/>Anil Ince<br/>Daimlerstraße 6 <br/>61449 Steinbach / Taunus</p><br/>
                    <p><strong>Vertretungsberechtigte Personen:</strong> Anil Ince</p><br/>
                    <p><strong>E-Mail-Adresse:</strong> <a href="mailto:info@venmedia.de">info@venmedia.de</a></p><br/>
                    <p><strong>Telefon:</strong> 06171 951 80 86</p><br/>
                    <p><strong>Impressum:</strong> www.venmedia.de</p><br/>
                    <h2 id="mOverview">Übersicht der Verarbeitungen</h2><p>Die nachfolgende Übersicht fasst die Arten der
                    verarbeiteten Daten und die Zwecke ihrer Verarbeitung zusammen und verweist auf die betroffenen
                    Personen.</p><h3>Arten der verarbeiteten Daten</h3><br/>
                    <ul>
                        <li>Bestandsdaten (z.B. Namen, Adressen).</li>
                        <li>Inhaltsdaten (z.B. Texteingaben, Fotografien, Videos).</li>
                        <li>Kontaktdaten (z.B. E-Mail, Telefonnummern).</li>
                        <li>Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).</li>
                        <li>Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten).</li>
                        <li>Standortdaten (Daten, die den Standort des Endgeräts eines Endnutzers angeben).</li>
                        <li>Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit, Kundenkategorie).</li>
                        <li>Zahlungsdaten (z.B. Bankverbindungen, Rechnungen, Zahlungshistorie).</li>
                    </ul>
                    <h3>Kategorien betroffener Personen</h3>
                    <ul>
                        <li>Beschäftigte (z.B. Angestellte, Bewerber, ehemalige Mitarbeiter).</li>
                        <li>Geschäfts- und Vertragspartner.</li>
                        <li>Interessenten.</li>
                        <li>Kommunikationspartner.</li>
                        <li>Kunden.</li>
                        <li>Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).</li>
                    </ul>
                    <h3>Zwecke der Verarbeitung</h3>
                    <ul>
                        <li>Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.</li>
                        <li>Besuchsaktionsauswertung.</li>
                        <li>Büro- und Organisationsverfahren.</li>
                        <li>Cross-Device Tracking (geräteübergreifende Verarbeitung von Nutzerdaten für Marketingzwecke).
                        </li>
                        <li>Direktmarketing (z.B. per E-Mail oder postalisch).</li>
                        <li>Interessenbasiertes und verhaltensbezogenes Marketing.</li>
                        <li>Kontaktanfragen und Kommunikation.</li>
                        <li>Konversionsmessung (Messung der Effektivität von Marketingmaßnahmen).</li>
                        <li>Profiling (Erstellen von Nutzerprofilen).</li>
                        <li>Remarketing.</li>
                        <li>Reichweitenmessung (z.B. Zugriffsstatistiken, Erkennung wiederkehrender Besucher).</li>
                        <li>Sicherheitsmaßnahmen.</li>
                        <li>Tracking (z.B. interessens-/verhaltensbezogenes Profiling, Nutzung von Cookies).</li>
                        <li>Vertragliche Leistungen und Service.</li>
                        <li>Verwaltung und Beantwortung von Anfragen.</li>
                        <li>Zielgruppenbildung (Bestimmung von für Marketingzwecke relevanten Zielgruppen oder sonstige
                            Ausgabe von Inhalten).
                        </li>
                    </ul>
                    <h3 id="m13">Maßgebliche Rechtsgrundlagen</h3><p>Im Folgenden teilen wir die Rechtsgrundlagen der
                    Datenschutzgrundverordnung (DSGVO), auf deren Basis wir die personenbezogenen Daten verarbeiten, mit.
                    Bitte beachten Sie, dass zusätzlich zu den Regelungen der DSGVO die nationalen Datenschutzvorgaben in
                    Ihrem bzw. unserem Wohn- und Sitzland gelten können. Sollten ferner im Einzelfall speziellere
                    Rechtsgrundlagen maßgeblich sein, teilen wir Ihnen diese in der Datenschutzerklärung mit.</p><br/>
                    <ul>
                        <li><strong>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO)</strong> - Die betroffene Person hat ihre
                            Einwilligung in die Verarbeitung der sie betreffenden personenbezogenen Daten für einen
                            spezifischen Zweck oder mehrere bestimmte Zwecke gegeben.
                        </li>
                        <li><strong>Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b.
                            DSGVO)</strong> - Die Verarbeitung ist für die Erfüllung eines Vertrags, dessen Vertragspartei
                            die betroffene Person ist, oder zur Durchführung vorvertraglicher Maßnahmen erforderlich, die
                            auf Anfrage der betroffenen Person erfolgen.
                        </li>
                        <li><strong>Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c. DSGVO)</strong> - Die Verarbeitung
                            ist zur Erfüllung einer rechtlichen Verpflichtung erforderlich, der der Verantwortliche
                            unterliegt.
                        </li>
                        <li><strong>Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO)</strong> - Die Verarbeitung
                            ist zur Wahrung der berechtigten Interessen des Verantwortlichen oder eines Dritten
                            erforderlich, sofern nicht die Interessen oder Grundrechte und Grundfreiheiten der betroffenen
                            Person, die den Schutz personenbezogener Daten erfordern, überwiegen.
                        </li>
                    </ul>
                    <p><strong>Nationale Datenschutzregelungen in Deutschland</strong>: Zusätzlich zu den
                        Datenschutzregelungen der Datenschutz-Grundverordnung gelten nationale Regelungen zum Datenschutz in
                        Deutschland. Hierzu gehört insbesondere das Gesetz zum Schutz vor Missbrauch personenbezogener Daten
                        bei der Datenverarbeitung (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält insbesondere
                        Spezialregelungen zum Recht auf Auskunft, zum Recht auf Löschung, zum Widerspruchsrecht, zur
                        Verarbeitung besonderer Kategorien personenbezogener Daten, zur Verarbeitung für andere Zwecke und
                        zur Übermittlung sowie automatisierten Entscheidungsfindung im Einzelfall einschließlich Profiling.
                        Des Weiteren regelt es die Datenverarbeitung für Zwecke des Beschäftigungsverhältnisses (§ 26 BDSG),
                        insbesondere im Hinblick auf die Begründung, Durchführung oder Beendigung von
                        Beschäftigungsverhältnissen sowie die Einwilligung von Beschäftigten. Ferner können
                        Landesdatenschutzgesetze der einzelnen Bundesländer zur Anwendung gelangen.</p><br/>
                    <h2 id="m27">Sicherheitsmaßnahmen</h2><p>Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter
                    Berücksichtigung des Stands der Technik, der Implementierungskosten und der Art, des Umfangs, der
                    Umstände und der Zwecke der Verarbeitung sowie der unterschiedlichen Eintrittswahrscheinlichkeiten und
                    des Ausmaßes der Bedrohung der Rechte und Freiheiten natürlicher Personen geeignete technische und
                    organisatorische Maßnahmen, um ein dem Risiko angemessenes Schutzniveau zu gewährleisten.</p><br/>
                    <p>Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität und Verfügbarkeit
                        von Daten durch Kontrolle des physischen und elektronischen Zugangs zu den Daten als auch des sie
                        betreffenden Zugriffs, der Eingabe, der Weitergabe, der Sicherung der Verfügbarkeit und ihrer
                        Trennung. Des Weiteren haben wir Verfahren eingerichtet, die eine Wahrnehmung von
                        Betroffenenrechten, die Löschung von Daten und Reaktionen auf die Gefährdung der Daten
                        gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener Daten bereits bei der
                        Entwicklung bzw. Auswahl von Hardware, Software sowie Verfahren entsprechend dem Prinzip des
                        Datenschutzes, durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen.</p><br/>
                    <h2 id="m25">Übermittlung und Offenbarung von personenbezogenen Daten</h2><p>Im Rahmen unserer
                    Verarbeitung von personenbezogenen Daten kommt es vor, dass die Daten an andere Stellen, Unternehmen,
                    rechtlich selbstständige Organisationseinheiten oder Personen übermittelt oder sie ihnen gegenüber
                    offengelegt werden. Zu den Empfängern dieser Daten können z.B. Zahlungsinstitute im Rahmen von
                    Zahlungsvorgängen, mit IT-Aufgaben beauftragte Dienstleister oder Anbieter von Diensten und Inhalten,
                    die in eine Webseite eingebunden werden, gehören. In solchen Fall beachten wir die gesetzlichen Vorgaben
                    und schließen insbesondere entsprechende Verträge bzw. Vereinbarungen, die dem Schutz Ihrer Daten
                    dienen, mit den Empfängern Ihrer Daten ab.</p><br/>
                    <h2 id="m24">Datenverarbeitung in Drittländern</h2><p>Sofern wir Daten in einem Drittland (d.h.,
                    außerhalb der Europäischen Union (EU), des Europäischen Wirtschaftsraums (EWR)) verarbeiten oder die
                    Verarbeitung im Rahmen der Inanspruchnahme von Diensten Dritter oder der Offenlegung bzw. Übermittlung
                    von Daten an andere Personen, Stellen oder Unternehmen stattfindet, erfolgt dies nur im Einklang mit den
                    gesetzlichen Vorgaben. </p><br/>
                    <p>Vorbehaltlich ausdrücklicher Einwilligung oder vertraglich oder gesetzlich erforderlicher
                        Übermittlung verarbeiten oder lassen wir die Daten nur in Drittländern mit einem anerkannten
                        Datenschutzniveau, vertraglichen Verpflichtung durch sogenannte Standardschutzklauseln der
                        EU-Kommission, beim Vorliegen von Zertifizierungen oder verbindlicher internen
                        Datenschutzvorschriften verarbeiten (Art. 44 bis 49 DSGVO, Informationsseite der EU-Kommission: <a
                            href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de"
                            target="_blank">https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de</a> ).
                    </p><br/>
                    <h2 id="m134">Einsatz von Cookies</h2><p>Cookies sind Textdateien, die Daten von besuchten Websites oder
                    Domains enthalten und von einem Browser auf dem Computer des Benutzers gespeichert werden. Ein Cookie
                    dient in erster Linie dazu, die Informationen über einen Benutzer während oder nach seinem Besuch
                    innerhalb eines Onlineangebotes zu speichern. Zu den gespeicherten Angaben können z.B. die
                    Spracheinstellungen auf einer Webseite, der Loginstatus, ein Warenkorb oder die Stelle, an der ein Video
                    geschaut wurde, gehören. Zu dem Begriff der Cookies zählen wir ferner andere Technologien, die die
                    gleichen Funktionen wie Cookies erfüllen (z.B., wenn Angaben der Nutzer anhand pseudonymer
                    Onlinekennzeichnungen gespeichert werden, auch als "Nutzer-IDs" bezeichnet)</p><br/>
                    <p><strong>Die folgenden Cookie-Typen und Funktionen werden unterschieden:</strong></p>
                    <ul>
                        <li><strong>Temporäre Cookies (auch: Session- oder Sitzungs-Cookies):</strong> Temporäre Cookies
                            werden spätestens gelöscht, nachdem ein Nutzer ein Online-Angebot verlassen und seinen Browser
                            geschlossen hat.
                        </li>
                        <li><strong>Permanente Cookies:</strong> Permanente Cookies bleiben auch nach dem Schließen des
                            Browsers gespeichert. So kann beispielsweise der Login-Status gespeichert oder bevorzugte
                            Inhalte direkt angezeigt werden, wenn der Nutzer eine Website erneut besucht. Ebenso können die
                            Interessen von Nutzern, die zur Reichweitenmessung oder zu Marketingzwecken verwendet werden, in
                            einem solchen Cookie gespeichert werden.
                        </li>
                        <li><strong>First-Party-Cookies:</strong> First-Party-Cookies werden von uns selbst gesetzt.</li>
                        <li><strong>Third-Party-Cookies (auch: Drittanbieter-Cookies)</strong>: Drittanbieter-Cookies werden
                            hauptsächlich von Werbetreibenden (sog. Dritten) verwendet, um Benutzerinformationen zu
                            verarbeiten.
                        </li>
                        <li><strong>Notwendige (auch: essentielle oder unbedingt erforderliche) Cookies:</strong> Cookies
                            können zum einen für den Betrieb einer Webseite unbedingt erforderlich sein (z.B. um Logins oder
                            andere Nutzereingaben zu speichern oder aus Gründen der Sicherheit).
                        </li>
                        <li><strong>Statistik-, Marketing- und Personalisierungs-Cookies</strong>: Ferner werden Cookies im
                            Regelfall auch im Rahmen der Reichweitenmessung eingesetzt sowie dann, wenn die Interessen eines
                            Nutzers oder sein Verhalten (z.B. Betrachten bestimmter Inhalte, Nutzen von Funktionen etc.) auf
                            einzelnen Webseiten in einem Nutzerprofil gespeichert werden. Solche Profile dienen dazu, den
                            Nutzern z.B. Inhalte anzuzeigen, die ihren potentiellen Interessen entsprechen. Dieses Verfahren
                            wird auch als "Tracking", d.h., Nachverfolgung der potentiellen Interessen der Nutzer
                            bezeichnet. . Soweit wir Cookies oder "Tracking"-Technologien einsetzen, informieren wir Sie
                            gesondert in unserer Datenschutzerklärung oder im Rahmen der Einholung einer Einwilligung.
                        </li>
                    </ul>
                    <p><strong>Hinweise zu Rechtsgrundlagen: </strong> Auf welcher Rechtsgrundlage wir Ihre
                        personenbezogenen Daten mit Hilfe von Cookies verarbeiten, hängt davon ab, ob wir Sie um eine
                        Einwilligung bitten. Falls dies zutrifft und Sie in die Nutzung von Cookies einwilligen, ist die
                        Rechtsgrundlage der Verarbeitung Ihrer Daten die erklärte Einwilligung. Andernfalls werden die
                        mithilfe von Cookies verarbeiteten Daten auf Grundlage unserer berechtigten Interessen (z.B. an
                        einem betriebswirtschaftlichen Betrieb unseres Onlineangebotes und dessen Verbesserung) verarbeitet
                        oder, wenn der Einsatz von Cookies erforderlich ist, um unsere vertraglichen Verpflichtungen zu
                        erfüllen.</p><br/>
                    <p><strong>Speicherdauer: </strong>Sofern wir Ihnen keine expliziten Angaben zur Speicherdauer von
                        permanenten Cookies mitteilen (z. B. im Rahmen eines sog. Cookie-Opt-Ins), gehen Sie bitte davon
                        aus, dass die Speicherdauer bis zu zwei Jahre betragen kann.</p><br/>
                    <p><strong>Allgemeine Hinweise zum Widerruf und Widerspruch (Opt-Out): </strong> Abhängig davon, ob die
                        Verarbeitung auf Grundlage einer Einwilligung oder gesetzlichen Erlaubnis erfolgt, haben Sie
                        jederzeit die Möglichkeit, eine erteilte Einwilligung zu widerrufen oder der Verarbeitung Ihrer
                        Daten durch Cookie-Technologien zu widersprechen (zusammenfassend als "Opt-Out" bezeichnet). Sie
                        können Ihren Widerspruch zunächst mittels der Einstellungen Ihres Browsers erklären, z.B., indem Sie
                        die Nutzung von Cookies deaktivieren (wobei hierdurch auch die Funktionsfähigkeit unseres
                        Onlineangebotes eingeschränkt werden kann). Ein Widerspruch gegen den Einsatz von Cookies zu Zwecken
                        des Onlinemarketings kann auch mittels einer Vielzahl von Diensten, vor allem im Fall des Trackings,
                        über die Webseiten <a href="https://optout.aboutads.info"
                                              target="_blank">https://optout.aboutads.info</a> und <a
                            href="https://www.youronlinices.com/" target="_blank">https://www.youronlinices.com/</a> erklärt
                        werden. Daneben können Sie weitere Widerspruchshinweise im Rahmen der Angaben zu den eingesetzten
                        Dienstleistern und Cookies erhalten.</p><br/>
                    <p><strong>Verarbeitung von Cookie-Daten auf Grundlage einer Einwilligung</strong>: Bevor wir Daten im
                        Rahmen der Nutzung von Cookies verarbeiten oder verarbeiten lassen, bitten wir die Nutzer um eine
                        jederzeit widerrufbare Einwilligung. Bevor die Einwilligung nicht ausgesprochen wurde, werden
                        allenfalls Cookies eingesetzt, die für den Betrieb unseres Onlineangebotes unbedingt erforderlich
                        sind.</p><br/>
                    <ul className="m-elements">
                        <li><strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte Webseiten, Interesse an
                            Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
                        </li>
                        <li><strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von
                            Onlinediensten).
                        </li>
                        <li><strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO), Berechtigte
                            Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
                        </li>
                    </ul>
                    <h2 id="m317">Kommerzielle und geschäftliche Leistungen</h2><p>Wir verarbeiten Daten unserer Vertrags-
                    und Geschäftspartner, z.B. Kunden und Interessenten (zusammenfassend bezeichnet als "Vertragspartner")
                    im Rahmen von vertraglichen und vergleichbaren Rechtsverhältnissen sowie damit verbundenen Maßnahmen und
                    im Rahmen der Kommunikation mit den Vertragspartnern (oder vorvertraglich), z.B., um Anfragen zu
                    beantworten.</p><br/>
                    <p>Diese Daten verarbeiten wir zur Erfüllung unserer vertraglichen Pflichten, zur Sicherung unserer
                        Rechte und zu Zwecken der mit diesen Angaben einhergehenden Verwaltungsaufgaben sowie der
                        unternehmerischen Organisation. Die Daten der Vertragspartner geben wir im Rahmen des geltenden
                        Rechts nur insoweit an Dritte weiter, als dies zu den vorgenannten Zwecken oder zur Erfüllung
                        gesetzlicher Pflichten erforderlich ist oder mit Einwilligung der betroffenen Personen erfolgt (z.B.
                        an beteiligte Telekommunikations-, Transport- und sonstige Hilfsdienste sowie Subunternehmer,
                        Banken, Steuer- und Rechtsberater, Zahlungsdienstleister oder Steuerbehörden). Über weitere
                        Verarbeitungsformen, z.B. zu Zwecken des Marketings, werden die Vertragspartner im Rahmen dieser
                        Datenschutzerklärung informiert.</p><br/>
                    <p>Welche Daten für die vorgenannten Zwecke erforderlich sind, teilen wir den Vertragspartnern vor oder
                        im Rahmen der Datenerhebung, z.B. in Onlineformularen, durch besondere Kennzeichnung (z.B. Farben)
                        bzw. Symbole (z.B. Sternchen o.ä.), oder persönlich mit.</p><br/>
                    <p>Wir löschen die Daten nach Ablauf gesetzlicher Gewährleistungs- und vergleichbarer Pflichten, d.h.,
                        grundsätzlich nach Ablauf von 4 Jahren, es sei denn, dass die Daten in einem Kundenkonto gespeichert
                        werden, z.B., solange sie aus gesetzlichen Gründen der Archivierung aufbewahrt werden müssen (z.B.
                        für Steuerzwecke im Regelfall 10 Jahre). Daten, die uns im Rahmen eines Auftrags durch den
                        Vertragspartner offengelegt wurden, löschen wir entsprechend den Vorgaben des Auftrags,
                        grundsätzlich nach Ende des Auftrags.</p><br/>
                    <p>Soweit wir zur Erbringung unserer Leistungen Drittanbieter oder Plattformen einsetzen, gelten im
                        Verhältnis zwischen den Nutzern und den Anbietern die Geschäftsbedingungen und Datenschutzhinweise
                        der jeweiligen Drittanbieter oder Plattformen.</p><br/>
                    <p><strong>Agenturdienstleistungen</strong>: Wir verarbeiten die Daten unserer Kunden im Rahmen unserer
                        vertraglichen Leistungen, zu denen z.B. konzeptionelle und strategische Beratung, Kampagnenplanung,
                        Software- und Designentwicklung/-beratung oder -pflege, Umsetzung von Kampagnen und Prozessen,
                        Handling, Serveradministration, Datenanalyse/ Beratungsleistungen und Schulungsleistungen gehören
                        können.</p><br/>
                    <ul className="m-elements">
                        <li><strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen), Zahlungsdaten
                            (z.B. Bankverbindungen, Rechnungen, Zahlungshistorie), Kontaktdaten (z.B. E-Mail,
                            Telefonnummern), Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit, Kundenkategorie).
                        </li>
                        <li><strong>Betroffene Personen:</strong> Interessenten, Geschäfts- und Vertragspartner.</li>
                        <li><strong>Zwecke der Verarbeitung:</strong> Vertragliche Leistungen und Service, Kontaktanfragen
                            und Kommunikation, Büro- und Organisationsverfahren, Verwaltung und Beantwortung von Anfragen.
                        </li>
                        <li><strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1
                            S. 1 lit. b. DSGVO), Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c. DSGVO), Berechtigte
                            Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
                        </li>
                    </ul>
                    <h2 id="m225">Bereitstellung des Onlineangebotes und Webhosting</h2><p>Um unser Onlineangebot sicher und
                    effizient bereitstellen zu können, nehmen wir die Leistungen von einem oder mehreren
                    Webhosting-Anbietern in Anspruch, von deren Servern (bzw. von ihnen verwalteten Servern) das
                    Onlineangebot abgerufen werden kann. Zu diesen Zwecken können wir Infrastruktur- und
                    Plattformdienstleistungen, Rechenkapazität, Speicherplatz und Datenbankdienste sowie
                    Sicherheitsleistungen und technische Wartungsleistungen in Anspruch nehmen.</p><br/>
                    <p>Zu den im Rahmen der Bereitstellung des Hostingangebotes verarbeiteten Daten können alle die Nutzer
                        unseres Onlineangebotes betreffenden Angaben gehören, die im Rahmen der Nutzung und der
                        Kommunikation anfallen. Hierzu gehören regelmäßig die IP-Adresse, die notwendig ist, um die Inhalte
                        von Onlineangeboten an Browser ausliefern zu können, und alle innerhalb unseres Onlineangebotes oder
                        von Webseiten getätigten Eingaben.</p><br/>
                    <p><strong>Erhebung von Zugriffsdaten und Logfiles</strong>: Wir selbst (bzw. unser Webhostinganbieter)
                        erheben Daten zu jedem Zugriff auf den Server (sogenannte Serverlogfiles). Zu den Serverlogfiles
                        können die Adresse und Name der abgerufenen Webseiten und Dateien, Datum und Uhrzeit des Abrufs,
                        übertragene Datenmengen, Meldung über erfolgreichen Abruf, Browsertyp nebst Version, das
                        Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte Seite) und im Regelfall IP-Adressen und
                        der anfragende Provider gehören.</p><br/>
                    <p>Die Serverlogfiles können zum einen zu Zwecken der Sicherheit eingesetzt werden, z.B., um eine
                        Überlastung der Server zu vermeiden (insbesondere im Fall von missbräuchlichen Angriffen,
                        sogenannten DDoS-Attacken) und zum anderen, um die Auslastung der Server und ihre Stabilität
                        sicherzustellen.</p><br/>
                    <ul className="m-elements">
                        <li><strong>Verarbeitete Datenarten:</strong> Inhaltsdaten (z.B. Texteingaben, Fotografien, Videos),
                            Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten),
                            Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
                        </li>
                        <li><strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von
                            Onlinediensten).
                        </li>
                        <li><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
                        </li>
                    </ul>
                    <h2 id="m469">Besondere Hinweise zu Applikationen (Apps)</h2>
                    <p>
                        <html_tags>Wir verarbeiten die Daten der Nutzer unserer Applikation, soweit diese erforderlich sind,
                            um den Nutzern die Applikation sowie deren Funktionalitäten bereitstellen, deren Sicherheit
                            überwachen und sie weiterentwickeln zu können. Wir können ferner Nutzer unter Beachtung der
                            gesetzlichen Vorgaben kontaktieren, sofern die Kommunikation zu Zwecken der Administration oder
                            Nutzung der Applikation erforderlich ist. Im Übrigen verweisen wir im Hinblick auf die
                            Verarbeitung der Daten der Nutzer auf die Datenschutzhinweise in dieser Datenschutzerklärung.
                        </html_tags>
                    </p>
                    <br/>
                    <p><strong>Rechtsgrundlagen: </strong>Die Verarbeitung von Daten, die für die Bereitstellung der
                        Funktionalitäten der Applikation erforderlich ist, dient der Erfüllung von vertraglichen
                        Pflichten. <br/>
                    </p><p><strong>Kommerzielle Nutzung</strong>: </p><br/>
                    <p>
                        <html_tags>Wir verarbeiten die Daten der Nutzer unserer Applikation, angemeldeter und etwaiger
                            Testnutzer (nachfolgend einheitlich als "Nutzer" bezeichnet), um ihnen gegenüber unsere
                            vertraglichen Leistungen erbringen zu können sowie auf Grundlage berechtigter Interessen, um
                            die Sicherheit unserer Applikation gewährleisten und sie weiterzuentwickeln <br/>
                        </html_tags>
                    </p>
                    <p><strong>Geräteberechtigungen für den Zugriff auf Funktionen und Daten</strong>: </p><br/>
                    <p>
                        <html_tags>Die Nutzung unserer Applikation oder ihrer Funktionalitäten kann Berechtigungen der
                            Nutzer für Zugriff auf bestimmten Funktionen der verwendeten Geräte oder auf die, auf den
                            Geräten gespeicherten oder mit Hilfe der Geräte zugänglichen Daten voraussetzen.
                            Standardmäßig müssen diese Berechtigungen von den Nutzern erteilt werden und können
                            jederzeit in den Einstellungen <br/>
                        </html_tags>
                    </p>
                    <div className="dsg-license-content-blurred">
                        <ul className="m-elements">
                            <li><strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen),
                                Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen), Zahlungsdaten (z.B.
                                Bankverbindungen, Rechnungen, Zahlungshistorie), Vertragsdaten (z.B. Vertragsgegenstand,
                                Laufzeit, Kundenkategorie).
                            </li>
                            <li><strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von
                                Onlinediensten).
                            </li>
                            <li><strong>Zwecke der Verarbeitung:</strong> Vertragliche Leistungen und Service.</li>
                            <li><strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO),
                                Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO),
                                Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
                            </li>
                        </ul>
                    </div>
                    <h2 id="m182">Kontaktaufnahme</h2><p>Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular,
                    E-Mail, Telefon oder via soziale Medien) werden die Angaben der anfragenden Personen verarbeitet,
                    soweit dies zur Beantwortung der Kontaktanfragen und etwaiger angefragter Maßnahmen erforderlich
                    ist.</p><br/>
                    <p>Die Beantwortung der Kontaktanfragen im Rahmen von vertraglichen oder vorvertraglichen
                        Beziehungen erfolgt zur Erfüllung unserer vertraglichen Pflichten oder zur Beantwortung von
                        (vor)vertraglichen Anfragen und im Übrigen auf Grundlage der berechtigten Interessen an der
                        Beantwortung der Anfragen.</p><br/>
                    <ul className="m-elements">
                        <li><strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen), Kontaktdaten
                            (z.B. E-Mail, Telefonnummern), Inhaltsdaten (z.B. Texteingaben, Fotografien, Videos).
                        </li>
                        <li><strong>Betroffene Personen:</strong> Kommunikationspartner.</li>
                        <li><strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und Kommunikation.</li>
                        <li><strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6
                            Abs. 1 S. 1 lit. b. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
                        </li>
                    </ul>
                    <h2 id="m29">Cloud-Dienste</h2><p>Wir nutzen über das Internet zugängliche und auf den Servern ihrer
                    Anbieter ausgeführte Softwaredienste (sogenannte "Cloud-Dienste", auch bezeichnet als "Software as a
                    Service") für die folgenden Zwecke: Dokumentenspeicherung und Verwaltung, Kalenderverwaltung,
                    E-Mail-Versand, Tabellenkalkulationen und Präsentationen, Austausch von Dokumenten, Inhalten und
                    Informationen mit bestimmten Empfängern oder Veröffentlichung von Webseiten, Formularen oder
                    sonstigen Inhalten und Informationen sowie Chats und Teilnahme an Audio- und Videokonferenzen.</p>
                    <br/>
                    <p>In diesem Rahmen können personenbezogenen Daten verarbeitet und auf den Servern der Anbieter
                        gespeichert werden, soweit diese Bestandteil von Kommunikationsvorgängen mit uns sind oder
                        von uns sonst, wie im Rahmen dieser Datenschutzerklärung dargelegt, verarbeitet werden. Zu
                        diesen Daten können insbesondere Stammdaten und Kontaktdaten der Nutzer, Daten zu Vorgängen,
                        Verträgen, sonstigen Prozessen und deren Inhalte gehören. Die Anbieter der Cloud-Dienste
                        verarbeiten ferner Nutzungsdaten und Metadaten, die von ihnen zu Sicherheitszwecken und zur
                        Serviceoptimierung verwendet werden.</p><br/>
                    <p>Sofern wir mit Hilfe der Cloud-Dienste für andere Nutzer oder öffentlich zugängliche
                        Webseiten Formulare o.a. Dokumente und Inhalte bereitstellen, können die Anbieter Cookies
                        auf den Geräten der Nutzer für Zwecke der Webanalyse oder, um sich Einstellungen der Nutzer
                        (z.B. im Fall der Mediensteuerung) zu merken, speichern.</p><br/>
                    <p><strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir um eine Einwilligung in den Einsatz
                        der Cloud-Dienste bitten, ist die Rechtsgrundlage der Verarbeitung die Einwilligung. Ferner
                        kann deren Einsatz ein Bestandteil unserer (vor)vertraglichen Leistungen sein, sofern der
                        Einsatz der Cloud-Dienste in diesem Rahmen vereinbart wurde. Ansonsten werden die Daten der
                        Nutzer auf Grundlage unserer berechtigten Interessen (d.h., Interesse an effizienten und
                        sicheren Verwaltungs- und Kollaborationsprozessen) verarbeitet</p><br/>
                    <ul className="m-elements">
                        <li><strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen),
                            Kontaktdaten (z.B. E-Mail, Telefonnummern), Inhaltsdaten (z.B. Texteingaben,
                            Fotografien, Videos), Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
                            Zugriffszeiten), Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
                        </li>
                        <li><strong>Betroffene Personen:</strong> Kunden, Beschäftigte (z.B. Angestellte, Bewerber,
                            ehemalige Mitarbeiter), Interessenten, Kommunikationspartner.
                        </li>
                        <li><strong>Zwecke der Verarbeitung:</strong> Büro- und Organisationsverfahren.</li>
                        <li><strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO),
                            Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO),
                            Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
                        </li>
                    </ul>
                    <h2 id="m17">Newsletter und elektronische Benachrichtigungen</h2><p>Wir versenden Newsletter,
                    E-Mails und weitere elektronische Benachrichtigungen (nachfolgend "Newsletter“) nur mit der
                    Einwilligung der Empfänger oder einer gesetzlichen Erlaubnis. Sofern im Rahmen einer Anmeldung
                    zum Newsletter dessen Inhalte konkret umschrieben werden, sind sie für die Einwilligung der
                    Nutzer maßgeblich. Im Übrigen enthalten unsere Newsletter Informationen zu unseren Leistungen
                    und uns.</p><br/>
                    <p>Um sich zu unseren Newslettern anzumelden, reicht es grundsätzlich aus, wenn Sie Ihre
                        E-Mail-Adresse angeben. Wir können Sie jedoch bitten, einen Namen, zwecks persönlicher
                        Ansprache im Newsletter, oder weitere Angaben, sofern diese für die Zwecke des Newsletters
                        erforderlich sind, zu tätigen.</p><br/>
                    <p><strong>Double-Opt-In-Verfahren:</strong> Die Anmeldung zu unserem Newsletter erfolgt
                        grundsätzlich in einem sogenannte Double-Opt-In-Verfahren. D.h., Sie erhalten nach der
                        Anmeldung eine E-Mail, in der Sie um die Bestätigung Ihrer Anmeldung gebeten werden. Diese
                        Bestätigung ist notwendig, damit sich niemand mit fremden E-Mail-Adressen anmelden kann. Die
                        Anmeldungen zum Newsletter werden protokolliert, um den Anmeldeprozess entsprechend den
                        rechtlichen Anforderungen nachweisen zu können. Hierzu gehört die Speicherung des Anmelde-
                        und des Bestätigungszeitpunkts als auch der IP-Adresse. Ebenso werden die Änderungen Ihrer
                        bei dem Versanddienstleister gespeicherten Daten protokolliert.</p><br/>
                    <p><strong>Löschung und Einschränkung der Verarbeitung: </strong> Wir können die ausgetragenen
                        E-Mail-Adressen bis zu drei Jahren auf Grundlage unserer berechtigten Interessen speichern,
                        bevor wir sie löschen, um eine ehemals gegebene Einwilligung nachweisen zu können. Die
                        Verarbeitung dieser Daten wird auf den Zweck einer möglichen Abwehr von Ansprüchen
                        beschränkt. Ein individueller Löschungsantrag ist jederzeit möglich, sofern zugleich das
                        ehemalige Bestehen einer Einwilligung bestätigt wird. Im Fall von Pflichten zur dauerhaften
                        Beachtung von Widersprüchen behalten wir uns die Speicherung der E-Mail-Adresse alleine zu
                        diesem Zweck in einer Sperrliste (sogenannte "Blacklist") vor.</p><br/>
                    <p>Die Protokollierung des Anmeldeverfahrens erfolgt auf Grundlage unserer berechtigten
                        Interessen zu Zwecken des Nachweises seines ordnungsgemäßen Ablaufs. Soweit wir einen
                        Dienstleister mit dem Versand von E-Mails beauftragen, erfolgt dies auf Grundlage unserer
                        berechtigten Interessen an einem effizienten und sicheren Versandsystem.</p><br/>
                    <p><strong>Hinweise zu Rechtsgrundlagen:</strong> Der Versand der Newsletter erfolgt auf
                        Grundlage einer Einwilligung der Empfänger oder, falls eine Einwilligung nicht erforderlich
                        ist, auf Grundlage unserer berechtigten Interessen am Direktmarketing, sofern und soweit
                        diese gesetzlich, z.B. im Fall von Bestandskundenwerbung, erlaubt ist. Soweit wir einen
                        Dienstleister mit dem Versand von E-Mails beauftragen, geschieht dies auf der Grundlage
                        unserer berechtigten Interessen. Das Registrierungsverfahren wird auf der Grundlage unserer
                        berechtigten Interessen aufgezeichnet, um nachzuweisen, dass es in Übereinstimmung mit dem
                        Gesetz durchgeführt wurde.</p><br/>
                    <p><strong>Inhalte:</strong> Informationen zu uns, unseren Leistungen, Aktionen und Angeboten.
                    </p><br/>
                    <p><strong>Analyse und Erfolgsmessung</strong>: Die Newsletter enthalten einen sogenannte
                        "web-beacon“, d.h., eine pixelgroße Datei, die beim Öffnen des Newsletters von unserem
                        Server, bzw., sofern wir einen Versanddienstleister einsetzen, von dessen Server abgerufen
                        wird. Im Rahmen dieses Abrufs werden zunächst technische Informationen, wie Informationen
                        zum Browser und Ihrem System, als auch Ihre IP-Adresse und der Zeitpunkt des Abrufs,
                        erhoben. </p><br/>
                    <p>Diese Informationen werden zur technischen Verbesserung unseres Newsletters anhand der
                        technischen Daten oder der Zielgruppen und ihres Leseverhaltens auf Basis ihrer Abruforte
                        (die mit Hilfe der IP-Adresse bestimmbar sind) oder der Zugriffszeiten genutzt. Diese
                        Analyse beinhaltet ebenfalls die Feststellung, ob die Newsletter geöffnet werden, wann sie
                        geöffnet werden und welche Links geklickt werden. Diese Informationen können aus technischen
                        Gründen zwar den einzelnen Newsletterempfängern zugeordnet werden. Es ist jedoch weder unser
                        Bestreben noch, sofern eingesetzt, das des Versanddienstleisters, einzelne Nutzer zu
                        beobachten. Die Auswertungen dienen uns vielmehr dazu, die Lesegewohnheiten unserer Nutzer
                        zu erkennen und unsere Inhalte an sie anzupassen oder unterschiedliche Inhalte entsprechend
                        den Interessen unserer Nutzer zu versenden.</p><br/>
                    <p>Die Auswertung des Newsletters und die Erfolgsmessung erfolgen, vorbehaltlich einer
                        ausdrücklichen Einwilligung der Nutzer, auf Grundlage unserer berechtigten Interessen zu
                        Zwecken des Einsatzes eines nutzerfreundlichen sowie sicheren Newslettersystems, welches
                        sowohl unseren geschäftlichen Interessen dient, als auch den Erwartungen der Nutzer
                        entspricht.</p><br/>
                    <p>Ein getrennter Widerruf der Erfolgsmessung ist leider nicht möglich, in diesem Fall muss das
                        gesamte Newsletterabonnement gekündigt, bzw. muss ihm widersprochen werden.</p><br/>
                    <ul className="m-elements">
                        <li><strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen),
                            Kontaktdaten (z.B. E-Mail, Telefonnummern), Meta-/Kommunikationsdaten (z.B.
                            Geräte-Informationen, IP-Adressen), Nutzungsdaten (z.B. besuchte Webseiten, Interesse an
                            Inhalten, Zugriffszeiten).
                        </li>
                        <li><strong>Betroffene Personen:</strong> Kommunikationspartner.</li>
                        <li><strong>Zwecke der Verarbeitung:</strong> Direktmarketing (z.B. per E-Mail oder
                            postalisch).
                        </li>
                        <li><strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO),
                            Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
                        </li>
                        <li><strong>Widerspruchsmöglichkeit (Opt-Out):</strong> Sie können den Empfang unseres
                            Newsletters jederzeit kündigen, d.h. Ihre Einwilligungen widerrufen, bzw. dem weiteren
                            Empfang widersprechen. Einen Link zur Kündigung des Newsletters finden Sie entweder am
                            Ende eines jeden Newsletters oder können sonst eine der oben angegebenen
                            Kontaktmöglichkeiten, vorzugswürdig E-Mail, hierzu nutzen.
                        </li>
                    </ul>
                    <h2 id="m638">Werbliche Kommunikation via E-Mail, Post, Fax oder Telefon</h2><p>Wir verarbeiten
                    personenbezogene Daten zu Zwecken der werblichen Kommunikation, die über diverse Kanäle, wie
                    z.B. E-Mail, Telefon, Post oder Fax, entsprechend den gesetzlichen Vorgaben erfolgen kann.</p>
                    <br/>
                    <p>Die Empfänger haben das Recht, erteilte Einwilligungen jederzeit zu widerrufen oder der
                        werblichen Kommunikation jederzeit zu widersprechen.</p><br/>
                    <p>Nach Widerruf bzw. Widerspruch können wir die zum Nachweis der Einwilligung
                        erforderlichen Daten bis zu drei Jahren auf Grundlage unserer berechtigten Interessen
                        speichern, bevor wir sie löschen. Die Verarbeitung dieser Daten wird auf den Zweck einer
                        möglichen Abwehr von Ansprüchen beschränkt. Ein individueller Löschungsantrag ist
                        jederzeit möglich, sofern zugleich das ehemalige Bestehen einer Einwilligung bestätigt
                        wird.</p><br/>
                    <ul className="m-elements">
                        <li><strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen),
                            Kontaktdaten (z.B. E-Mail, Telefonnummern).
                        </li>
                        <li><strong>Betroffene Personen:</strong> Kommunikationspartner.</li>
                        <li><strong>Zwecke der Verarbeitung:</strong> Direktmarketing (z.B. per E-Mail oder
                            postalisch).
                        </li>
                        <li><strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO),
                            Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
                        </li>
                    </ul>
                    <h2 id="m263">Webanalyse, Monitoring und Optimierung</h2><p>Die Webanalyse (auch als
                    "Reichweitenmessung" bezeichnet) dient der Auswertung der Besucherströme unseres
                    Onlineangebotes und kann Verhalten, Interessen oder demographische Informationen zu den
                    Besuchern, wie z.B. das Alter oder das Geschlecht, als pseudonyme Werte umfassen. Mit Hilfe
                    der Reichweitenanalyse können wir z.B. erkennen, zu welcher Zeit unser Onlineangebot oder
                    dessen Funktionen oder Inhalte am häufigsten genutzt werden oder zur Wiederverwendung
                    einladen. Ebenso können wir nachvollziehen, welche Bereiche der Optimierung bedürfen. </p>
                    <br/>
                    <p>Neben der Webanalyse können wir auch Testverfahren einsetzen, um z.B.
                        unterschiedliche Versionen unseres Onlineangebotes oder seiner Bestandteile zu
                        testen und optimieren.</p><br/>
                    <p>Zu diesen Zwecken können sogenannte Nutzerprofile angelegt und in einer Datei
                        (sogenannte "Cookie") gespeichert oder ähnliche Verfahren mit dem gleichen Zweck
                        genutzt werden. Zu diesen Angaben können z.B. betrachtete Inhalte, besuchte
                        Webseiten und dort genutzte Elemente und technische Angaben, wie der verwendete
                        Browser, das verwendete Computersystem sowie Angaben zu Nutzungszeiten gehören.
                        Sofern Nutzer in die Erhebung ihrer Standortdaten eingewilligt haben, können je nach
                        Anbieter auch diese verarbeitet werden.</p><br/>
                    <p>Es werden ebenfalls die IP-Adressen der Nutzer gespeichert. Jedoch nutzen wir ein
                        IP-Masking-Verfahren (d.h., Pseudonymisierung durch Kürzung der IP-Adresse) zum
                        Schutz der Nutzer. Generell werden die im Rahmen von Webanalyse, A/B-Testings und
                        Optimierung keine Klardaten der Nutzer (wie z.B. E-Mail-Adressen oder Namen)
                        gespeichert, sondern Pseudonyme. D.h., wir als auch die Anbieter der eingesetzten
                        Software kennen nicht die tatsächliche Identität der Nutzer, sondern nur den für
                        Zwecke der jeweiligen Verfahren in deren Profilen gespeicherten Angaben.</p><br/>
                    <p><strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Nutzer um deren
                        Einwilligung in den Einsatz der Drittanbieter bitten, ist die Rechtsgrundlage der
                        Verarbeitung von Daten die Einwilligung. Ansonsten werden die Daten der Nutzer auf
                        Grundlage unserer berechtigten Interessen (d.h. Interesse an effizienten,
                        wirtschaftlichen und empfängerfreundlichen Leistungen) verarbeitet. In diesem
                        Zusammenhang möchten wir Sie auch auf die Informationen zur Verwendung von Cookies
                        in dieser Datenschutzerklärung hinweisen.</p><br/>
                    <ul className="m-elements">
                        <li><strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von
                            Onlinediensten).
                        </li>
                        <li><strong>Zwecke der Verarbeitung:</strong> Reichweitenmessung (z.B.
                            Zugriffsstatistiken, Erkennung wiederkehrender Besucher), Tracking (z.B.
                            interessens-/verhaltensbezogenes Profiling, Nutzung von Cookies),
                            Besuchsaktionsauswertung, Profiling (Erstellen von Nutzerprofilen).
                        </li>
                        <li><strong>Sicherheitsmaßnahmen:</strong> IP-Masking (Pseudonymisierung der
                            IP-Adresse).
                        </li>
                        <li><strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a
                            DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
                        </li>
                    </ul>
                    <h2 id="m264">Onlinemarketing</h2><p>Wir verarbeiten personenbezogene Daten zu Zwecken
                    des Onlinemarketings, worunter insbesondere die Vermarktung von Werbeflächen oder
                    Darstellung von werbenden und sonstigen Inhalten (zusammenfassend als "Inhalte"
                    bezeichnet) anhand potentieller Interessen der Nutzer sowie die Messung ihrer
                    Effektivität fallen kann. </p><br/>
                    <p>Zu diesen Zwecken werden sogenannte Nutzerprofile angelegt und in einer Datei
                        (sogenannte "Cookie") gespeichert oder ähnliche Verfahren genutzt, mittels derer die
                        für die Darstellung der vorgenannten Inhalte relevante Angaben zum Nutzer
                        gespeichert werden. Zu diesen Angaben können z.B. betrachtete Inhalte, besuchte
                        Webseiten, genutzte Onlinenetzwerke, aber auch Kommunikationspartner und technische
                        Angaben, wie der verwendete Browser, das verwendete Computersystem sowie Angaben zu
                        Nutzungszeiten gehören. Sofern Nutzer in die Erhebung ihrer Standortdaten
                        eingewilligt haben, können auch diese verarbeitet werden.</p><br/>
                    <p>Es werden ebenfalls die IP-Adressen der Nutzer gespeichert. Jedoch nutzen wir zur
                        Verfügung stehende IP-Masking-Verfahren (d.h., Pseudonymisierung durch Kürzung der
                        IP-Adresse) zum Schutz der Nutzer. Generell werden im Rahmen des
                        Onlinemarketingverfahren keine Klardaten der Nutzer (wie z.B. E-Mail-Adressen oder
                        Namen) gespeichert, sondern Pseudonyme. D.h., wir als auch die Anbieter der
                        Onlinemarketingverfahren kennen nicht die tatsächlich Identität der Nutzer, sondern
                        nur die in deren Profilen gespeicherten Angaben.</p><br/>
                    <p>Die Angaben in den Profilen werden im Regelfall in den Cookies oder mittels ähnlicher
                        Verfahren gespeichert. Diese Cookies können später generell auch auf anderen
                        Webseiten die dasselbe Onlinemarketingverfahren einsetzen, ausgelesen und zu Zwecken
                        der Darstellung von Inhalten analysiert als auch mit weiteren Daten ergänzt und auf
                        dem Server des Onlinemarketingverfahrensanbieters gespeichert werden.</p><br/>
                    <p>Ausnahmsweise können Klardaten den Profilen zugeordnet werden. Das ist der Fall, wenn
                        die Nutzer z.B. Mitglieder eines sozialen Netzwerks sind, dessen
                        Onlinemarketingverfahren wir einsetzen und das Netzwerk die Profile der Nutzer mit
                        den vorgenannten Angaben verbindet. Wir bitten darum, zu beachten, dass Nutzer mit
                        den Anbietern zusätzliche Abreden, z.B. durch Einwilligung im Rahmen der
                        Registrierung, treffen können.</p><br/>
                    <p>Wir erhalten grundsätzlich nur Zugang zu zusammengefassten Informationen über den
                        Erfolg unserer Werbeanzeigen. Jedoch können wir im Rahmen sogenannter
                        Konversionsmessungen prüfen, welche unserer Onlinemarketingverfahren zu einer
                        sogenannten Konversion geführt haben, d.h. z.B., zu einem Vertragsschluss mit uns.
                        Die Konversionsmessung wird alleine zur Analyse des Erfolgs unserer
                        Marketingmaßnahmen verwendet.</p><br/>
                    <p>Solange nicht anders angegeben, bitten wir Sie davon auszugehen, dass verwendete
                        Cookies für einen Zeitraum von zwei Jahren gespeichert werden.</p><br/>
                    <p><strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Nutzer um deren
                        Einwilligung in den Einsatz der Drittanbieter bitten, ist die Rechtsgrundlage der
                        Verarbeitung von Daten die Einwilligung. Ansonsten werden die Daten der Nutzer auf
                        Grundlage unserer berechtigten Interessen (d.h. Interesse an effizienten,
                        wirtschaftlichen und empfängerfreundlichen Leistungen) verarbeitet. In diesem
                        Zusammenhang möchten wir Sie auch auf die Informationen zur Verwendung von Cookies
                        in dieser Datenschutzerklärung hinweisen.</p><br/>
                    <p><strong>Facebook-Pixel</strong>: Mit Hilfe des Facebook-Pixels ist es Facebook zum
                        einen möglich, die Besucher unseres Onlineangebotes als Zielgruppe für die
                        Darstellung von Anzeigen (sogenannte "Facebook-Ads") zu bestimmen. Dementsprechend
                        setzen wir das Facebook-Pixel ein, um die durch uns geschalteten Facebook-Ads nur
                        solchen Nutzern bei Facebook und innerhalb der Dienste der mit Facebook
                        kooperierenden Partner (so genanntes "Audience Network" <a
                            href="https://www.facebook.com/audiencenetwork/"
                            target="_blank">https://www.facebook.com/audiencenetwork/</a> ) anzuzeigen, die
                        auch ein Interesse an unserem Onlineangebot gezeigt haben oder die bestimmte
                        Merkmale (z.B. Interesse an bestimmten Themen oder Produkten, die anhand der
                        besuchten Webseiten ersichtlich werden) aufweisen, die wir an Facebook übermitteln
                        (sogenannte "Custom Audiences“). Mit Hilfe des Facebook-Pixels möchten wir auch
                        sicherstellen, dass unsere Facebook-Ads dem potentiellen Interesse der Nutzer
                        entsprechen und nicht belästigend wirken. Mit Hilfe des Facebook-Pixels können wir
                        ferner die Wirksamkeit der Facebook-Werbeanzeigen für statistische und
                        Marktforschungszwecke nachvollziehen, indem wir sehen, ob Nutzer nach dem Klick auf
                        eine Facebook-Werbeanzeige auf unsere Webseite weitergeleitet wurden (sogenannte
                        "Konversionsmessung“).</p><br/>
                    <ul className="m-elements">
                        <li><strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte
                            Webseiten, Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten
                            (z.B. Geräte-Informationen, IP-Adressen), Standortdaten (Daten, die den Standort
                            des Endgeräts eines Endnutzers angeben).
                        </li>
                        <li><strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von
                            Onlinediensten), Interessenten.
                        </li>
                        <li><strong>Zwecke der Verarbeitung:</strong> Tracking (z.B.
                            interessens-/verhaltensbezogenes Profiling, Nutzung von Cookies), Remarketing,
                            Besuchsaktionsauswertung, Interessenbasiertes und verhaltensbezogenes Marketing,
                            Profiling (Erstellen von Nutzerprofilen), Konversionsmessung (Messung der
                            Effektivität von Marketingmaßnahmen), Reichweitenmessung (z.B.
                            Zugriffsstatistiken, Erkennung wiederkehrender Besucher), Zielgruppenbildung
                            (Bestimmung von für Marketingzwecke relevanten Zielgruppen oder sonstige Ausgabe
                            von Inhalten), Cross-Device Tracking (geräteübergreifende Verarbeitung von
                            Nutzerdaten für Marketingzwecke).
                        </li>
                        <li><strong>Sicherheitsmaßnahmen:</strong> IP-Masking (Pseudonymisierung der
                            IP-Adresse).
                        </li>
                        <li><strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a
                            DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
                        </li>
                        <li><strong>Widerspruchsmöglichkeit (Opt-Out):</strong> Wir verweisen auf die
                            Datenschutzhinweise der jeweiligen Anbieter und die zu den Anbietern angegebenen
                            Widerspruchsmöglichkeiten (sog. "Opt-Out"). Sofern keine explizite
                            Opt-Out-Möglichkeit angegeben wurde, besteht zum einen die Möglichkeit, dass Sie
                            Cookies in den Einstellungen Ihres Browsers abschalten. Hierdurch können jedoch
                            Funktionen unseres Onlineangebotes eingeschränkt werden. Wir empfehlen daher
                            zusätzlich die folgenden Opt-Out-Möglichkeiten, die zusammenfassend auf
                            jeweilige Gebiete gerichtet angeboten werden:<br/>
                            <br/>
                            a) Europa: <a href="https://www.youronlinices.eu"
                                          target="_blank">https://www.youronlinices.eu</a>. <br/>
                            b) Kanada: <a href="https://www.youradchoices.ca/choices"
                                          target="_blank">https://www.youradchoices.ca/choices</a>. <br/>
                            c) USA: <a href="https://www.aboutads.info/choices"
                                       target="_blank">https://www.aboutads.info/choices</a>. <br/>
                            d) Gebietsübergreifend: <a href="https://optout.aboutads.info"
                                                       target="_blank">https://optout.aboutads.info</a>.
                        </li>
                    </ul>
                    <p><strong>Eingesetzte Dienste und Diensteanbieter:</strong></p>
                    <ul className="m-elements">
                        <li><strong>Google Analytics:</strong> Onlinemarketing und Webanalyse;
                            Dienstanbieter: Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
                            Irland, Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain View,
                            CA 94043, USA; Website: <a
                                href="https://marketingplatform.google.com/intl/de/about/analytics/"
                                target="_blank">https://marketingplatform.google.com/intl/de/about/analytics/</a>;
                            Datenschutzerklärung: <a href="https://policies.google.com/privacy"
                                                     target="_blank">https://policies.google.com/privacy</a>;
                            Widerspruchsmöglichkeit (Opt-Out): Opt-Out-Plugin: <a
                                href="https://tools.google.com/dlpage/gaoptout?hl=de"
                                target="_blank">https://tools.google.com/dlpage/gaoptout?hl=de</a>,
                            Einstellungen für die Darstellung von Werbeeinblendungen: <a
                                href="https://adssettings.google.com/authenticated"
                                target="_blank">https://adssettings.google.com/authenticated</a>.
                        </li>
                        <li><strong>Facebook-Pixel:</strong> Dienstanbieter: <a
                            href="https://www.facebook.com" target="_blank">https://www.facebook.com</a>,
                            Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2,
                            Irland, Mutterunternehmen: Facebook, 1 Hacker Way, Menlo Park, CA 94025, USA;
                            Website: <a href="https://www.facebook.com"
                                        target="_blank">https://www.facebook.com</a>;
                            Datenschutzerklärung: <a href="https://www.facebook.com/about/privacy"
                                                     target="_blank">https://www.facebook.com/about/privacy</a>;
                            Widerspruchsmöglichkeit (Opt-Out): <a
                                href="https://www.facebook.com/settings?tab=ads"
                                target="_blank">https://www.facebook.com/settings?tab=ads</a>.
                        </li>
                    </ul>
                    <h2 id="m136">Präsenzen in sozialen Netzwerken (Social Media)</h2><p>Wir unterhalten
                    Onlinepräsenzen innerhalb sozialer Netzwerke und verarbeiten in diesem Rahmen Daten der
                    Nutzer, um mit den dort aktiven Nutzern zu kommunizieren oder um Informationen über uns
                    anzubieten.</p><br/>
                    <p>Wir weisen darauf hin, dass dabei Daten der Nutzer außerhalb des Raumes der
                        Europäischen Union verarbeitet werden können. Hierdurch können sich für die Nutzer
                        Risiken ergeben, weil so z.B. die Durchsetzung der Rechte der Nutzer erschwert
                        werden könnte.</p><br/>
                    <p>Ferner werden die Daten der Nutzer innerhalb sozialer Netzwerke im Regelfall für
                        Marktforschungs- und Werbezwecke verarbeitet. So können z.B. anhand des
                        Nutzungsverhaltens und sich daraus ergebender Interessen der Nutzer Nutzungsprofile
                        erstellt werden. Die Nutzungsprofile können wiederum verwendet werden, um z.B.
                        Werbeanzeigen innerhalb und außerhalb der Netzwerke zu schalten, die mutmaßlich den
                        Interessen der Nutzer entsprechen. Zu diesen Zwecken werden im Regelfall Cookies auf
                        den Rechnern der Nutzer gespeichert, in denen das Nutzungsverhalten und die
                        Interessen der Nutzer gespeichert werden. Ferner können in den Nutzungsprofilen auch
                        Daten unabhängig der von den Nutzern verwendeten Geräte gespeichert werden
                        (insbesondere, wenn die Nutzer Mitglieder der jeweiligen Plattformen sind und bei
                        diesen eingeloggt sind).</p><br/>
                    <p>Für eine detaillierte Darstellung der jeweiligen Verarbeitungsformen und der
                        Widerspruchsmöglichkeiten (Opt-Out) verweisen wir auf die Datenschutzerklärungen und
                        Angaben der Betreiber der jeweiligen Netzwerke.</p><br/>
                    <p>Auch im Fall von Auskunftsanfragen und der Geltendmachung von Betroffenenrechten
                        weisen wir darauf hin, dass diese am effektivsten bei den Anbietern geltend gemacht
                        werden können. Nur die Anbieter haben jeweils Zugriff auf die Daten der Nutzer und
                        können direkt entsprechende Maßnahmen ergreifen und Auskünfte geben. Sollten Sie
                        dennoch Hilfe benötigen, dann können Sie sich an uns wenden.</p><br/>
                    <ul className="m-elements">
                        <li><strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen),
                            Kontaktdaten (z.B. E-Mail, Telefonnummern), Inhaltsdaten (z.B. Texteingaben,
                            Fotografien, Videos), Nutzungsdaten (z.B. besuchte Webseiten, Interesse an
                            Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B. Geräte-Informationen,
                            IP-Adressen).
                        </li>
                        <li><strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von
                            Onlinediensten).
                        </li>
                        <li><strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und Kommunikation,
                            Tracking (z.B. interessens-/verhaltensbezogenes Profiling, Nutzung von Cookies),
                            Remarketing, Reichweitenmessung (z.B. Zugriffsstatistiken, Erkennung
                            wiederkehrender Besucher).
                        </li>
                        <li><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1
                            lit. f. DSGVO).
                        </li>
                    </ul>
                    <p><strong>Eingesetzte Dienste und Diensteanbieter:</strong></p>
                    <ul className="m-elements">
                        <li><strong>Instagram :</strong> Soziales Netzwerk; Dienstanbieter: Instagram Inc.,
                            1601 Willow Road, Menlo Park, CA, 94025, USA; Website: <a
                                href="https://www.instagram.com"
                                target="_blank">https://www.instagram.com</a>; Datenschutzerklärung: <a
                                href="https://instagram.com/about/legal/privacy"
                                target="_blank">https://instagram.com/about/legal/privacy</a>.
                        </li>
                        <li><strong>Facebook:</strong> Soziales Netzwerk; Dienstanbieter: Facebook Ireland
                            Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland,
                            Mutterunternehmen: Facebook, 1 Hacker Way, Menlo Park, CA 94025, USA;
                            Website: <a href="https://www.facebook.com"
                                        target="_blank">https://www.facebook.com</a>;
                            Datenschutzerklärung: <a href="https://www.facebook.com/about/privacy"
                                                     target="_blank">https://www.facebook.com/about/privacy</a>;
                            Widerspruchsmöglichkeit (Opt-Out): Einstellungen für Werbeanzeigen: <a
                                href="https://www.facebook.com/settings?tab=ads"
                                target="_blank">https://www.facebook.com/settings?tab=ads</a>; Zusätzliche
                            Hinweise zum Datenschutz: Vereinbarung über gemeinsame Verarbeitung
                            personenbezogener Daten auf Facebook-Seiten: <a
                                href="https://www.facebook.com/legal/terms/page_controller_addendum"
                                target="_blank">https://www.facebook.com/legal/terms/page_controller_addendum</a>,
                            Datenschutzhinweise für Facebook-Seiten: <a
                                href="https://www.facebook.com/legal/terms/information_about_page_insights_data"
                                target="_blank">https://www.facebook.com/legal/terms/information_about_page_insights_data</a>.
                        </li>
                        <li><strong>LinkedIn:</strong> Soziales Netzwerk; Dienstanbieter: LinkedIn Ireland
                            Unlimited Company, Wilton Place, Dublin 2, Irland; Website: <a
                                href="https://www.linkedin.com" target="_blank">https://www.linkedin.com</a>;
                            Datenschutzerklärung: <a href="https://www.linkedin.com/legal/privacy-policy"
                                                     target="_blank">https://www.linkedin.com/legal/privacy-policy</a>;
                            Widerspruchsmöglichkeit (Opt-Out): <a
                                href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out"
                                target="_blank">https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out</a>.
                        </li>
                        <li><strong>Twitter:</strong> Soziales Netzwerk; Dienstanbieter: Twitter Inc., 1355
                            Market Street, Suite 900, San Francisco, CA 94103, USA; Datenschutzerklärung: <a
                                href="https://twitter.com/de/privacy"
                                target="_blank">https://twitter.com/de/privacy</a>, (Einstellungen) <a
                                href="https://twitter.com/personalization"
                                target="_blank">https://twitter.com/personalization</a>.
                        </li>
                        <li><strong>Xing:</strong> Soziales Netzwerk; Dienstanbieter: XING AG, Dammtorstraße
                            29-32, 20354 Hamburg, Deutschland; Website: <a href="https://www.xing.de"
                                                                           target="_blank">https://www.xing.de</a>;
                            Datenschutzerklärung: <a
                                href="https://privacy.xing.com/de/datenschutzerklaerung"
                                target="_blank">https://privacy.xing.com/de/datenschutzerklaerung</a>.
                        </li>
                    </ul>
                    <h2 id="m328">Plugins und eingebettete Funktionen sowie Inhalte</h2><p>Wir binden in
                    unser Onlineangebot Funktions- und Inhaltselemente ein, die von den Servern ihrer
                    jeweiligen Anbieter (nachfolgend bezeichnet als "Drittanbieter”) bezogen werden. Dabei
                    kann es sich zum Beispiel um Grafiken, Videos oder Social-Media-Schaltflächen sowie
                    Beiträge handeln (nachfolgend einheitlich bezeichnet als "Inhalte”).</p><br/>
                    <p>Die Einbindung setzt immer voraus, dass die Drittanbieter dieser Inhalte die
                        IP-Adresse der Nutzer verarbeiten, da sie ohne die IP-Adresse die Inhalte nicht an
                        deren Browser senden könnten. Die IP-Adresse ist damit für die Darstellung dieser
                        Inhalte oder Funktionen erforderlich. Wir bemühen uns, nur solche Inhalte zu
                        verwenden, deren jeweilige Anbieter die IP-Adresse lediglich zur Auslieferung der
                        Inhalte verwenden. Drittanbieter können ferner sogenannte Pixel-Tags (unsichtbare
                        Grafiken, auch als "Web Beacons" bezeichnet) für statistische oder Marketingzwecke
                        verwenden. Durch die "Pixel-Tags" können Informationen, wie der Besucherverkehr auf
                        den Seiten dieser Webseite, ausgewertet werden. Die pseudonymen Informationen können
                        ferner in Cookies auf dem Gerät der Nutzer gespeichert werden und unter anderem
                        technische Informationen zum Browser und zum Betriebssystem, zu verweisenden
                        Webseiten, zur Besuchszeit sowie weitere Angaben zur Nutzung unseres Onlineangebotes
                        enthalten als auch mit solchen Informationen aus anderen Quellen verbunden
                        werden.</p><br/>
                    <p><strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Nutzer um deren
                        Einwilligung in den Einsatz der Drittanbieter bitten, ist die Rechtsgrundlage der
                        Verarbeitung von Daten die Einwilligung. Ansonsten werden die Daten der Nutzer auf
                        Grundlage unserer berechtigten Interessen (d.h. Interesse an effizienten,
                        wirtschaftlichen und empfängerfreundlichen Leistungen) verarbeitet. In diesem
                        Zusammenhang möchten wir Sie auch auf die Informationen zur Verwendung von Cookies
                        in dieser Datenschutzerklärung hinweisen.</p><br/>
                    <ul className="m-elements">
                        <li><strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte
                            Webseiten, Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten
                            (z.B. Geräte-Informationen, IP-Adressen), Standortdaten (Daten, die den Standort
                            des Endgeräts eines Endnutzers angeben), Bestandsdaten (z.B. Namen, Adressen),
                            Kontaktdaten (z.B. E-Mail, Telefonnummern), Inhaltsdaten (z.B. Texteingaben,
                            Fotografien, Videos).
                        </li>
                        <li><strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von
                            Onlinediensten).
                        </li>
                        <li><strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres Onlineangebotes
                            und Nutzerfreundlichkeit, Vertragliche Leistungen und Service,
                            Sicherheitsmaßnahmen, Verwaltung und Beantwortung von Anfragen.
                        </li>
                        <li><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1
                            lit. f. DSGVO), Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO),
                            Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b.
                            DSGVO).
                        </li>
                    </ul>
                    <p><strong>Eingesetzte Dienste und Diensteanbieter:</strong></p>
                    <ul className="m-elements">
                        <li><strong>Google Fonts:</strong> Wir binden die Schriftarten ("Google Fonts") des
                            Anbieters Google ein, wobei die Daten der Nutzer allein zu Zwecken der
                            Darstellung der Schriftarten im Browser der Nutzer verwendet werden. Die
                            Einbindung erfolgt auf Grundlage unserer berechtigten Interessen an einer
                            technisch sicheren, wartungsfreien und effizienten Nutzung von Schriftarten,
                            deren einheitlicher Darstellung sowie unter Berücksichtigung möglicher
                            lizenzrechtlicher Restriktionen für deren Einbindung. Dienstanbieter: Google
                            Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland,
                            Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA
                            94043, USA; Website: <a href="https://fonts.google.com/"
                                                    target="_blank">https://fonts.google.com/</a>;
                            Datenschutzerklärung: <a href="https://policies.google.com/privacy"
                                                     target="_blank">https://policies.google.com/privacy</a>.
                        </li>
                        <li><strong>Google Maps:</strong> Wir binden die Landkarten des Dienstes “Google
                            Maps” des Anbieters Google ein. Zu den verarbeiteten Daten können insbesondere
                            IP-Adressen und Standortdaten der Nutzer gehören, die jedoch nicht ohne deren
                            Einwilligung (im Regelfall im Rahmen der Einstellungen ihrer Mobilgeräte
                            vollzogen), erhoben werden; Dienstanbieter: Google Ireland Limited, Gordon
                            House, Barrow Street, Dublin 4, Irland, Mutterunternehmen: Google LLC, 1600
                            Amphitheatre Parkway, Mountain View, CA 94043, USA; Website: <a
                                href="https://cloud.google.com/maps-platform"
                                target="_blank">https://cloud.google.com/maps-platform</a>;
                            Datenschutzerklärung: <a href="https://policies.google.com/privacy"
                                                     target="_blank">https://policies.google.com/privacy</a>;
                            Widerspruchsmöglichkeit (Opt-Out): Opt-Out-Plugin: <a
                                href="https://tools.google.com/dlpage/gaoptout?hl=de"
                                target="_blank">https://tools.google.com/dlpage/gaoptout?hl=de</a>,
                            Einstellungen für die Darstellung von Werbeeinblendungen: <a
                                href="https://adssettings.google.com/authenticated"
                                target="_blank">https://adssettings.google.com/authenticated</a>.
                        </li>
                        <li><strong>YouTube-Videos:</strong> Videoinhalte; Dienstanbieter: Google Ireland
                            Limited, Gordon House, Barrow Street, Dublin 4, Irland, Mutterunternehmen:
                            Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; Website: <a
                                href="https://www.youtube.com" target="_blank">https://www.youtube.com</a>;
                            Datenschutzerklärung: <a href="https://policies.google.com/privacy"
                                                     target="_blank">https://policies.google.com/privacy</a>;
                            Widerspruchsmöglichkeit (Opt-Out): Opt-Out-Plugin: <a
                                href="https://tools.google.com/dlpage/gaoptout?hl=de"
                                target="_blank">https://tools.google.com/dlpage/gaoptout?hl=de</a>,
                            Einstellungen für die Darstellung von Werbeeinblendungen: <a
                                href="https://adssettings.google.com/authenticated"
                                target="_blank">https://adssettings.google.com/authenticated</a>.
                        </li>
                    </ul>
                    <h2 id="m12">Löschung von Daten</h2><p>Die von uns verarbeiteten Daten werden nach
                    Maßgabe der gesetzlichen Vorgaben gelöscht, sobald deren zur Verarbeitung erlaubten
                    Einwilligungen widerrufen werden oder sonstige Erlaubnisse entfallen (z.B., wenn der
                    Zweck der Verarbeitung dieser Daten entfallen ist oder sie für den Zweck nicht
                    erforderlich sind).</p><br/>
                    <p>Sofern die Daten nicht gelöscht werden, weil sie für andere und gesetzlich zulässige
                        Zwecke erforderlich sind, wird deren Verarbeitung auf diese Zwecke beschränkt. D.h.,
                        die Daten werden gesperrt und nicht für andere Zwecke verarbeitet. Das gilt z.B. für
                        Daten, die aus handels- oder steuerrechtlichen Gründen aufbewahrt werden müssen oder
                        deren Speicherung zur Geltendmachung, Ausübung oder Verteidigung von
                        Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder
                        juristischen Person erforderlich ist.</p><br/>
                    <p>Weitere Hinweise zu der Löschung von personenbezogenen Daten können ferner im Rahmen
                        der einzelnen Datenschutzhinweise dieser Datenschutzerklärung erfolgen.</p><br/>
                    <h2 id="m15">Änderung und Aktualisierung der Datenschutzerklärung</h2><p>Wir bitten Sie,
                    sich regelmäßig über den Inhalt unserer Datenschutzerklärung zu informieren. Wir passen
                    die Datenschutzerklärung an, sobald die Änderungen der von uns durchgeführten
                    Datenverarbeitungen dies erforderlich machen. Wir informieren Sie, sobald durch die
                    Änderungen eine Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder eine sonstige
                    individuelle Benachrichtigung erforderlich wird.</p><br/>
                    <p>Sofern wir in dieser Datenschutzerklärung Adressen und Kontaktinformationen von
                        Unternehmen und Organisationen angeben, bitten wir zu beachten, dass die Adressen
                        sich über die Zeit ändern können und bitten die Angaben vor Kontaktaufnahme zu
                        prüfen.</p><br/>
                    <h2 id="m10">Rechte der betroffenen Personen</h2><p>Ihnen stehen als Betroffene nach der
                    DSGVO verschiedene Rechte zu, die sich insbesondere aus Art. 15 bis 21 DSGVO
                    ergeben:</p>
                    <ul>
                        <li><strong>Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich aus Ihrer
                            besonderen Situation ergeben, jederzeit gegen die Verarbeitung der Sie
                            betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder
                            f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese
                            Bestimmungen gestütztes Profiling. Werden die Sie betreffenden personenbezogenen
                            Daten verarbeitet, um Direktwerbung zu betreiben, haben Sie das Recht, jederzeit
                            Widerspruch gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten
                            zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling,
                            soweit es mit solcher Direktwerbung in Verbindung steht.</strong></li>
                        <li><strong>Widerrufsrecht bei Einwilligungen:</strong> Sie haben das Recht,
                            erteilte Einwilligungen jederzeit zu widerrufen.
                        </li>
                        <li><strong>Auskunftsrecht:</strong> Sie haben das Recht, eine Bestätigung darüber
                            zu verlangen, ob betreffende Daten verarbeitet werden und auf Auskunft über
                            diese Daten sowie auf weitere Informationen und Kopie der Daten entsprechend den
                            gesetzlichen Vorgaben.
                        </li>
                        <li><strong>Recht auf Berichtigung:</strong> Sie haben entsprechend den gesetzlichen
                            Vorgaben das Recht, die Vervollständigung der Sie betreffenden Daten oder die
                            Berichtigung der Sie betreffenden unrichtigen Daten zu verlangen.
                        </li>
                        <li><strong>Recht auf Löschung und Einschränkung der Verarbeitung:</strong> Sie
                            haben nach Maßgabe der gesetzlichen Vorgaben das Recht, zu verlangen, dass Sie
                            betreffende Daten unverzüglich gelöscht werden, bzw. alternativ nach Maßgabe der
                            gesetzlichen Vorgaben eine Einschränkung der Verarbeitung der Daten zu
                            verlangen.
                        </li>
                        <li><strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das Recht, Sie
                            betreffende Daten, die Sie uns bereitgestellt haben, nach Maßgabe der
                            gesetzlichen Vorgaben in einem strukturierten, gängigen und maschinenlesbaren
                            Format zu erhalten oder deren Übermittlung an einen anderen Verantwortlichen zu
                            fordern.
                        </li>
                        <li><strong>Beschwerde bei Aufsichtsbehörde:</strong> Sie haben ferner nach Maßgabe
                            der gesetzlichen Vorgaben das Recht, bei einer Aufsichtsbehörde, insbesondere in
                            dem Mitgliedstaat Ihres gewöhnlichen Aufenthaltsorts, Ihres Arbeitsplatzes oder
                            des Orts des mutmaßlichen Verstoßes Beschwerde einzulegen, wenn Sie der Ansicht
                            sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen
                            die DSGVO verstößt.
                        </li>
                    </ul>
                    <br/>
                    <h2 id="m42">Begriffsdefinitionen</h2><p>In diesem Abschnitt erhalten Sie eine
                    Übersicht über die in dieser Datenschutzerklärung verwendeten Begrifflichkeiten.
                    Viele der Begriffe sind dem Gesetz entnommen und vor allem im Art. 4 DSGVO
                    definiert. Die gesetzlichen Definitionen sind verbindlich. Die nachfolgenden
                    Erläuterungen sollen dagegen vor allem dem Verständnis dienen. Die Begriffe sind
                    alphabetisch sortiert.</p><br/>
                    <ul className="glossary">
                        <li><strong>Besuchsaktionsauswertung:</strong> "Besuchsaktionsauswertung"
                            (englisch "Conversion Tracking") bezeichnet ein Verfahren, mit dem die
                            Wirksamkeit von Marketingmaßnahmen festgestellt werden kann. Dazu wird im
                            Regelfall ein Cookie auf den Geräten der Nutzer innerhalb der Webseiten, auf
                            denen die Marketingmaßnahmen erfolgen, gespeichert und dann erneut auf der
                            Zielwebseite abgerufen. Beispielsweise können wir so nachvollziehen, ob die
                            von uns auf anderen Webseiten geschalteten Anzeigen erfolgreich waren).
                        </li>
                        <li><strong>Cross-Device Tracking:</strong> Das Cross-Device Tracking ist eine
                            Form des Trackings, bei der Verhaltens- und Interessensinformationen der
                            Nutzer geräteübergreifend in sogenannten Profilen erfasst werden, indem den
                            Nutzern eine Onlinekennung zugeordnet wird. Hierdurch können die
                            Nutzerinformationen unabhängig von verwendeten Browsern oder Geräten (z.B.
                            Mobiltelefonen oder Desktopcomputern) im Regelfall für Marketingzwecke
                            analysiert werden. Die Onlinekennung ist bei den meisten Anbietern nicht mit
                            Klardaten, wie Namen, Postadressen oder E-Mail-Adressen, verknüpft.
                        </li>
                        <li><strong>IP-Masking:</strong> Als "IP-Masking” wird eine Methode bezeichnet,
                            bei der das letzte Oktett, d.h., die letzten beiden Zahlen einer IP-Adresse,
                            gelöscht wird, damit die IP-Adresse nicht mehr der eindeutigen
                            Identifizierung einer Person dienen kann. Daher ist das IP-Masking ein
                            Mittel zur Pseudonymisierung von Verarbeitungsverfahren, insbesondere im
                            Onlinemarketing
                        </li>
                        <li><strong>Interessenbasiertes und verhaltensbezogenes Marketing:</strong> Von
                            interessens- und/oder verhaltensbezogenem Marketing spricht man, wenn
                            potentielle Interessen von Nutzern an Anzeigen und sonstigen Inhalten
                            möglichst genau vorbestimmt werden. Dies geschieht anhand von Angaben zu
                            deren Vorverhalten (z.B. Aufsuchen von bestimmten Webseiten und Verweilen
                            auf diesen, Kaufverhaltens oder Interaktion mit anderen Nutzern), die in
                            einem sogenannten Profil gespeichert werden. Zu diesen Zwecken werden im
                            Regelfall Cookies eingesetzt.
                        </li>
                        <li><strong>Konversionsmessung:</strong> Die Konversionsmessung ist ein
                            Verfahren, mit dem die Wirksamkeit von Marketingmaßnahmen festgestellt
                            werden kann. Dazu wird im Regelfall ein Cookie auf den Geräten der Nutzer
                            innerhalb der Webseiten, auf denen die Marketingmaßnahmen erfolgen,
                            gespeichert und dann erneut auf der Zielwebseite abgerufen. Beispielsweise
                            können wir so nachvollziehen, ob die von uns auf anderen Webseiten
                            geschalteten Anzeigen erfolgreich waren.
                        </li>
                        <li><strong>Personenbezogene Daten:</strong> "Personenbezogene Daten“ sind alle
                            Informationen, die sich auf eine identifizierte oder identifizierbare
                            natürliche Person (im Folgenden "betroffene Person“) beziehen; als
                            identifizierbar wird eine natürliche Person angesehen, die direkt oder
                            indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen,
                            zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung (z.B. Cookie)
                            oder zu einem oder mehreren besonderen Merkmalen identifiziert werden kann,
                            die Ausdruck der physischen, physiologischen, genetischen, psychischen,
                            wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen
                            Person sind.
                        </li>
                        <li><strong>Profiling:</strong> Als "Profiling“ wird jede Art der
                            automatisierten Verarbeitung personenbezogener Daten bezeichnet, die darin
                            besteht, dass diese personenbezogenen Daten verwendet werden, um bestimmte
                            persönliche Aspekte, die sich auf eine natürliche Person beziehen (je nach
                            Art des Profilings gehören dazu Informationen betreffend das Alter, das
                            Geschlecht, Standortdaten und Bewegungsdaten, Interaktion mit Webseiten und
                            deren Inhalten, Einkaufsverhalten, soziale Interaktionen mit anderen
                            Menschen) zu analysieren, zu bewerten oder, um sie vorherzusagen (z.B. die
                            Interessen an bestimmten Inhalten oder Produkten, das Klickverhalten auf
                            einer Webseite oder den Aufenthaltsort). Zu Zwecken des Profilings werden
                            häufig Cookies und Web-Beacons eingesetzt.
                        </li>
                        <li><strong>Reichweitenmessung:</strong> Die Reichweitenmessung (auch als Web
                            Analytics bezeichnet) dient der Auswertung der Besucherströme eines
                            Onlineangebotes und kann das Verhalten oder Interessen der Besucher an
                            bestimmten Informationen, wie z.B. Inhalten von Webseiten, umfassen. Mit
                            Hilfe der Reichweitenanalyse können Webseiteninhaber z.B. erkennen, zu
                            welcher Zeit Besucher ihre Webseite besuchen und für welche Inhalte sie sich
                            interessieren. Dadurch können sie z.B. die Inhalte der Webseite besser an
                            die Bedürfnisse ihrer Besucher anpassen. Zu Zwecken der Reichweitenanalyse
                            werden häufig pseudonyme Cookies und Web-Beacons eingesetzt, um
                            wiederkehrende Besucher zu erkennen und so genauere Analysen zur Nutzung
                            eines Onlineangebotes zu erhalten.
                        </li>
                        <li><strong>Remarketing:</strong> Vom "Remarketing“ bzw. "Retargeting“ spricht
                            man, wenn z.B. zu Werbezwecken vermerkt wird, für welche Produkte sich ein
                            Nutzer auf einer Webseite interessiert hat, um den Nutzer auf anderen
                            Webseiten an diese Produkte, z.B. in Werbeanzeigen, zu erinnern.
                        </li>
                        <li><strong>Tracking:</strong> Vom "Tracking“ spricht man, wenn das Verhalten
                            von Nutzern über mehrere Onlineangebote hinweg nachvollzogen werden kann. Im
                            Regelfall werden im Hinblick auf die genutzten Onlineangebote Verhaltens-
                            und Interessensinformationen in Cookies oder auf Servern der Anbieter der
                            Trackingtechnologien gespeichert (sogenanntes Profiling). Diese
                            Informationen können anschließend z.B. eingesetzt werden, um den Nutzern
                            Werbeanzeigen anzuzeigen, die voraussichtlich deren Interessen entsprechen.
                        </li>
                        <li><strong>Verantwortlicher:</strong> Als "Verantwortlicher“ wird die
                            natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle,
                            die allein oder gemeinsam mit anderen über die Zwecke und Mittel der
                            Verarbeitung von personenbezogenen Daten entscheidet, bezeichnet.
                        </li>
                        <li><strong>Verarbeitung:</strong> "Verarbeitung" ist jeder mit oder ohne Hilfe
                            automatisierter Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe
                            im Zusammenhang mit personenbezogenen Daten. Der Begriff reicht weit und
                            umfasst praktisch jeden Umgang mit Daten, sei es das Erheben, das Auswerten,
                            das Speichern, das Übermitteln oder das Löschen.
                        </li>
                        <li><strong>Zielgruppenbildung:</strong> Von Zielgruppenbildung (bzw. "Custom
                            Audiences“) spricht man, wenn Zielgruppen für Werbezwecke, z.B. Einblendung
                            von Werbeanzeigen, bestimmt werden. So kann z.B. anhand des Interesses eines
                            Nutzers an bestimmten Produkten oder Themen im Internet geschlussfolgert
                            werden, dass dieser Nutzer sich für Werbeanzeigen für ähnliche Produkte oder
                            den Onlineshop, in dem er die Produkte betrachtet hat, interessiert. Von
                            "Lookalike Audiences“ (bzw. ähnlichen Zielgruppen) spricht man wiederum,
                            wenn die als geeignet eingeschätzten Inhalte Nutzern angezeigt werden, deren
                            Profile bzw. Interessen mutmaßlich den Nutzern, zu denen die Profile
                            gebildet wurden, entsprechen. Zu Zwecken der Bildung von Custom Audiences
                            und Lookalike Audiences werden im Regelfall Cookies und Web-Beacons
                            eingesetzt.
                        </li>
                    </ul>
                    <p className="seal"><a href="https://datenschutz-generator.de/"
                                           title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
                                           target="_blank" rel="noopener noreferrer nofollow">Erstellt
                        mit kostenlosem Datenschutz-Generator.de von Dr. Thomas Schwenke</a></p><br/>
                    <br/><br/>
                    <br/><br/>
                    <br/>
                    <div className="clear"></div>


                </div>
            </div>
        </div>
    )
}
export default Form