import {Bounce, Slide, Zoom} from "react-awesome-reveal";
import styles from "./Form.module.css";
import React, {useState} from "react";
import image from "./image.png";
import welleBottomImg from "../area6/welleBottom.svg";

function Form(props) {


    const [isChecklistDownload, setIsChecklistDownload] = useState(false);

    const actions = {
        post: () => {


        },
    }

    return (
        <div>

            <div style={{background: 'rgba(255,255,255,0.8)'}}>

                <div className="wrapper">
                    {isChecklistDownload == false && (
                        <div className="grid">
                            <div className="lg:col-6 col-12">
                                <Slide direction="left" triggerOnce>
                                    <div>
                                        <div className="venmediaHeader">Checkliste</div>
                                        <br/>
                                        <div className="venmediaHeader3">Der einfache Weg zum Erklärvideo!</div>
                                        <br/>
                                        <div style={{fontSize: 'clamp(12px, 2.8vw, 22px)'}}>
                                            Unsere Checkliste gibt Ihnen den einfachen Überblick über alles, was Sie für ein Erklärvideo benötigen. Einfach herunterladen und schon mal alle Vorbereitungen treffen.
                                        </div>

                                        <br/>
                                        <Bounce delay={700}>
                                            <center>
                                                <div
                                                    className={styles.checklistButton}
                                                    style={{width: '300px'}}
                                                    onClick={() => {
                                                        window.open("/checkliste.pdf", '_blank')
                                                        setIsChecklistDownload(true);
                                                    }}>
                                                    Zur Checkliste
                                                </div>
                                            </center>
                                        </Bounce>
                                    </div>
                                </Slide>
                            </div>
                            <div className="lg:col-6 col-12">
                                <Slide direction="right" triggerOnce>
                                    <img className={styles.image} src={image}/>
                                </Slide>
                            </div>
                        </div>
                    )}

                    {isChecklistDownload && (
                        <div>
                            checklist is send
                        </div>
                    )}

                </div>
                <br/>
                <br/>
                <br/>
            </div>
        </div>

    )
}
export default Form