import React, { Component } from 'react';
import TrackVisibility from "react-on-screen";
import styles from './EEFadeIn.module.css';

class EEFadeIn extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {

        const FadeIn = (props) => {
            return (
                <div className={ props.isVisible ? styles.fadeIn : styles.fadeInInvisible} orientation={props.orientation}>
                    {props.children}
                </div>);
        }

        return (
            <TrackVisibility once>
                <FadeIn orientation={this.props.orientation}>
                    {this.props.children}
                </FadeIn>
            </TrackVisibility>
        )

    }
}

export default EEFadeIn;