import styles from '../Form.module.css';
import React, {useEffect} from "react";
import img1 from "./img1.png";
import img2 from "./img2.png";
import KontaktForm from "../../kontakt/Form";
import {Bounce, Slide, Zoom} from "react-awesome-reveal";
import imgTwitter from "./imgTwitter.png";
import imgFacebook from "./imgFacebook.png";
import imgTikTok from "./imgTikTok.png";
import imgInstagram from "./imgInstagram.png";
import imgXing from "./imgXing.png";
import imgYoutube from "./imgYoutube.png";
import imgWhatsapp from "./imgWhatsapp.png";

function Form(props) {
    const video = React.createRef()
    useEffect(() => { video.current.play(); },[]);
    return (
        <div>
            <div className={styles.top}>
                <div className="wrapper">
                    <div className="grid">
                        <div className="col-4">

                            <Zoom triggerOnce delay="30">
                                <div style={{padding: '20px 20px 20px 20px', width: '100%'}}>
                                    <div style={{textAlign: 'left'}}>
                                        <video
                                            onMouseOver={() => {
                                                //video.current.muted = false;
                                                //video.current.play();
                                            }}
                                            controls
                                            muted
                                            autoPlay={"autoplay"}
                                            preload="auto"
                                            loop
                                            ref={video}
                                            width="100%"
                                            id="video">
                                            <source src="/venclip.mp4" type="video/mp4" />
                                        </video>
                                    </div>
                                </div>
                            </Zoom>
                        </div>
                        <div className="col-8">
                            <div>
                                <span className={styles.label}>Venclip</span>
                                <br/>
                                <br/>
                                <div>
                                    Animierte Videos wecken Emotionen.
                                    <br/><br/>
                                    In der heutigen dynamischen und stetig wachsenden Welt der Social Media Plattformen ist es von entscheidender Bedeutung, Ihre Zielgruppe effektiv zu erreichen und ihre Aufmerksamkeit zu gewinnen. Mit einem durchdachten Auftritt in den sozialen Medien können Sie Ihre Botschaften weitreichend verbreiten und Ihre Markenbekanntheit steigern. Doch in der Fülle an Inhalten und Informationen, die täglich auf den Social Media Kanälen veröffentlicht werden, bleibt nur wenig Zeit, um das Interesse potenzieller Zuschauer zu wecken und sie zum Verweilen zu bewegen.
                                    <br/><br/>
                                    Hier setzen animierte Erklärvideos neue Maßstäbe und eröffnen Ihnen eine Welt voller kreativer Möglichkeiten. Diese Videos haben das Potenzial, innerhalb von Sekunden eine starke emotionale Verbindung zu Ihrer Zielgruppe herzustellen und somit die Entscheidung über einen Klick positiv zu beeinflussen.

                                </div>


                            </div>
                        </div>
                    </div>

                    <div>
                        <div>
                            <div style={{float: 'left'}}>
                                <span style={{margin: "0px 10px 0px 3px", fontWeight: 'bold', fontSize: 20}}>Perfekt für:</span>

                            </div>
                            <div style={{float: 'left'}}>
                                <img src={imgTwitter} style={{height: '25px'}}/>
                                <span style={{margin: "0px 10px 0px 3px"}}>Twitter</span>
                            </div>
                            <div style={{float: 'left'}}>
                                <img src={imgFacebook} style={{height: '25px'}}/>
                                <span style={{margin: "0px 10px 0px 3px"}}>Facebook</span>
                            </div>
                            <div style={{float: 'left'}}>
                                <img src={imgTikTok} style={{height: '25px'}}/>
                                <span style={{margin: "0px 10px 0px 3px"}}>TikTok</span>
                            </div>
                            <div style={{float: 'left'}}>
                                <img src={imgInstagram} style={{height: '25px'}}/>
                                <span style={{margin: "0px 10px 0px 3px"}}>Instagram</span>
                            </div>
                            <div style={{float: 'left'}}>
                                <img src={imgXing} style={{height: '25px'}}/>
                                <span style={{margin: "0px 10px 0px 3px"}}>Xing</span>
                            </div>
                            <div style={{float: 'left'}}>
                                <img src={imgYoutube} style={{height: '25px'}}/>
                                <span style={{margin: "0px 10px 0px 3px"}}>YouTube</span>
                            </div>
                            <div style={{float: 'left'}}>
                                <img src={imgWhatsapp} style={{height: '25px'}}/>
                                <span style={{margin: "0px 10px 0px 3px"}}>WhatsApp</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            <div>
                <div className="wrapper">

                    <center>
                        <div className={"venmediaHeader3 " + styles.itemHeader}>Einige der vielfältigen Vorteile, die animierte Erklärvideos für Ihre Social Media Kanäle bieten</div>
                    </center>

                    <div className={styles.items}>
                        <div>
                            <div className={styles.item4}>
                                <Bounce delay="50"  triggerOnce>
                                    <div>
                                        <div className={"venmediaHeader3 " + styles.itemHeader}>Höhere Aufmerksamkeit</div>
                                        <div className={styles.itemContent}>
                                            Die visuelle und bewegte Natur von Animationen zieht die Blicke der Betrachter auf sich und hebt sich von statischen Inhalten ab. Dadurch erhöhen sich die Chancen, dass Nutzer Ihr Video entdecken und sich dafür interessieren.
                                        </div>
                                    </div>
                                </Bounce>
                            </div>
                            <div className={styles.item4}>
                                <Bounce delay="100"  triggerOnce>
                                    <div>
                                        <div className={"venmediaHeader3 " + styles.itemHeader}>Klarheit und Verständlichkeit</div>
                                        <div className={styles.itemContent}>
                                            Komplexe Ideen oder Produkte lassen sich oft schwer in Worte fassen. Animationsvideos ermöglichen es, abstrakte Konzepte anschaulich und leicht verständlich darzustellen. Die Kombination aus Bildern, Text und Audio vermittelt Ihre Botschaft auf eine einprägsame Weise.
                                        </div>
                                    </div>
                                </Bounce>
                            </div>
                            <div className={styles.item4}>
                                <Bounce delay="150"  triggerOnce>
                                    <div>
                                        <div className={"venmediaHeader3 " + styles.itemHeader}>Steigerung der Markenbekanntheit</div>
                                        <div className={styles.itemContent}>
                                            Konsistente Markendarstellungen in Ihren animierten Erklärvideos sorgen dafür, dass Ihr Unternehmen oder Ihre Marke in den Köpfen der Zuschauer präsent bleibt. Dies trägt langfristig zur Steigerung der Markenbekanntheit bei.
                                        </div>
                                    </div>
                                </Bounce>
                            </div>
                            <div className={styles.item4}>
                                <Bounce delay="200"  triggerOnce>
                                    <div>
                                        <div className={"venmediaHeader3 " + styles.itemHeader}>Virales Potenzial</div>
                                        <div className={styles.itemContent}>
                                            Ein kreatives, unterhaltsames oder informatives animiertes Erklärvideo hat das Potenzial, viral zu gehen. Wenn es die Zuschauer begeistert, teilen sie es gerne mit ihren Freunden und erzeugen so eine organische Reichweite.
                                        </div>
                                    </div>
                                </Bounce>
                            </div>
                            <div className={styles.item4}>
                                <Bounce delay="250"  triggerOnce>
                                    <div>
                                        <div className={"venmediaHeader3 " + styles.itemHeader}>Flexibilität und Anpassungsfähigkeit</div>
                                        <div className={styles.itemContent}>
                                            Animationsvideos können in verschiedenen Stilen erstellt werden - von humorvoll und verspielt bis hin zu seriös und professionell. Sie können Ihre Videos an die spezifischen Eigenschaften und Vorlieben Ihrer Zielgruppe anpassen.
                                        </div>
                                    </div>
                                </Bounce>
                            </div>
                            <div className={styles.item4}>
                                <Bounce delay="300"  triggerOnce>
                                    <div>
                                        <div className={"venmediaHeader3 " + styles.itemHeader}>Multilinguale Ansprache</div>
                                        <div className={styles.itemContent}>
                                            Da animierte Erklärvideos ohne gesprochenen Text auskommen können oder einfach untertitelt werden können, ermöglichen sie eine einfache Anpassung an verschiedene Sprachen und Kulturen, um internationale Zielgruppen anzusprechen.
                                        </div>
                                    </div>
                                </Bounce>
                            </div>
                            <div className={styles.item4}>
                                <Bounce delay="350"  triggerOnce>
                                    <div>
                                        <div className={"venmediaHeader3 " + styles.itemHeader}>Messbare Ergebnisse</div>
                                        <div className={styles.itemContent}>
                                            Die Erfolgsmessung Ihrer animierten Erklärvideos ist in den sozialen Medien gut möglich. Sie können die Interaktionsraten, Klicks und Conversion-Rates verfolgen und so den Erfolg Ihrer Kampagnen genau analysieren.
                                        </div>
                                    </div>
                                </Bounce>
                            </div>
                            <div className={styles.item4}>
                                <Bounce delay="400"  triggerOnce>
                                    <div>
                                        <div className={"venmediaHeader3 " + styles.itemHeader}>Wieder-Erkennungswert</div>
                                        <div className={styles.itemContent}>
                                            Ein einzigartiger Animationsstil kann Ihre Marke in den Köpfen Ihrer Zielgruppe verankern und zu einem hohen Wiedererkennungswert führen.
                                        </div>
                                    </div>
                                </Bounce>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrapper">

                <div  style={{textAlign: 'center'}}>

                    <div className="venmediaHeader">Mit einem Animierte Videos zu mehr Aufmerksamkeit!</div>

                    Bei uns stehen Ihre individuellen Bedürfnisse im Mittelpunkt. Unser kreatives Team entwickelt maßgeschneiderte animierte Erklärvideos, die genau auf Ihre Zielgruppe und Ihre Marketingziele abgestimmt sind. Egal ob es darum geht, Ihr neues Produkt vorzustellen, komplexe Prozesse zu erklären oder Ihre Markenbotschaft zu vermitteln - wir bringen Ihre Ideen zum Leben und lassen sie in den sozialen Medien erstrahlen.
                    <br/><br/>
                    Setzen Sie sich mit uns in Verbindung und entdecken Sie die faszinierende Welt der animierten Erklärvideos für Ihren Social Media Erfolg!

                </div>
            </div>

            <KontaktForm/>
        </div>
    )
}
export default Form