import styles from './Form.module.css';

function Form(props) {
    return (
        <div className="wrapper">
                <div ref={props.childRef}>

                        <h1>Kontakt</h1>

                        <b>VENmedia</b><br/>
                        Anil Ince<br/>
                        Daimlerstraße 6,<br/>
                        61449 Steinbach / Taunus<br/><br/>

                        <b>Kontakt:</b><br/>
                        Tel.: 06171 951 80 86<br/>
                        E-Mail: info@venmedia.de<br/><br/>

                        Geschäftsführer: Anil Ince<br/>
                        USt.-IdNr. gem. § 27a Umsatzsteuergesetz: DE 320859141<br/>
                        Steuer-Nr.: 014/830/31625<br/><br/>

                        Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:<br/>
                        Anil Ince<br/>
                        Daimlerstraße 6<br/>
                        61449 Steinbach / Taunus<br/><br/>

                        <b>Service:</b><br/>
                        Montag – Freitag<br/>
                        von 10:00 Uhr – 18:00Uhr
                </div>
        </div>
    )
}
export default Form