import styles from "./Form.module.css";
import Line1Img from "./Line1.png"
import Line2Img from "./Line2.png"
import ErstegesprächImg from "./Erstegespräch.png"
import StoryboardImg from "./Storyboard.png"
import AnimationImg from "./Animation.png"
import pfeilUnten from "./pfeilUnten.png"
import pfeilOben from "./pfeilOben.png"
import {useState} from "react";

function Form(props) {

    const [mouseEnteredIndex, setMouseEnteredIndex] = useState(0);

    const VisibilityTracker = (visibilityProps) => {
        if(visibilityProps.isVisible) {
            return visibilityProps.children
        } else {
            return (
                <div>
                    {visibilityProps.children}
                </div>);
        }
    }

    return (
        <div style={{background: 'rgba(255,255,255,0.2)'}}>
            <div className="wrapper" >
                <div ref={props.childRef}>

                    <div className="venmediaHeader">Ablauf</div>

                    <div className="grid">
                        <div className="lg:col-6 col-12">
                            <div
                                className={styles.item}
                                onMouseEnter={() => setMouseEnteredIndex(1)}
                                onMouseLeave={() => setMouseEnteredIndex(0)}>

                                <div className="grid">
                                    <div className="col-6">
                                        <img src={ErstegesprächImg} style={{width: '100%'}}/>
                                    </div>
                                    <div className="col-6">
                                        <div className={styles.itemHeader}>Erstgespräch</div>
                                        In einem Kick-Off-Gespräch sprechen wir mit Ihnen sämtliche Ideen für Ihr individuelles Erklärvideo ab. Basierend auf unserer Erfahrung beraten wir Sie.
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="hidden lg:inline-flex lg:col-6 lg:align-items-end">
                            <img src={Line2Img} style={{width: 100}}/>
                        </div>








                        <div className="hidden lg:inline-block lg:col-3">
                            <div style={{textAlign: 'right'}}>
                                <img src={pfeilUnten} width={100}/>
                            </div>
                        </div>
                        <div className="lg:col-6 col-12">
                            <div
                                className={styles.item}
                                onMouseEnter={() => setMouseEnteredIndex(2)}
                                onMouseLeave={() => setMouseEnteredIndex(0)}>
                                <div className="grid">
                                    <div className="col-6">
                                        <img src={StoryboardImg} style={{width: '100%'}}/>
                                    </div>
                                    <div className="col-6">
                                        <div className={styles.itemHeader}>Storyboard Konzeption</div>
                                        Experten aus unterschiedlichen Richtungen tauchen in die von Ihnen bereitgestellten Inhalte und Informationen ein, um ein zielgerichtetes Storyboard zu erstellen.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="hidden lg:inline-flex lg:col-3 lg:align-items-end">
                            <img src={pfeilOben} width={90}/>
                        </div>





                        <div className="hidden lg:inline-block lg:col-3"></div>
                        <div className="hidden lg:inline-block lg:col-3">
                            <div style={{textAlign: 'right'}}>
                                <img src={Line1Img} width={100}/>
                            </div>
                        </div>
                        <div className="lg:col-6 col-12">
                            <div
                                className={styles.item}
                                onMouseEnter={() => setMouseEnteredIndex(3)}
                                onMouseLeave={() => setMouseEnteredIndex(0)}>


                                <div className="grid">
                                    <div className="col-6">
                                        <img src={AnimationImg} style={{width: '100%'}}/>
                                    </div>

                                    <div className="col-6">
                                        <div className={styles.itemHeader}>Animation</div>
                                        Sobald Sie mit der Konzeption 100%ig zufrieden sind, macht sich unser Kreativteam mit viel Hingabe ans Werk, um mit passenden Bildern und Animationen Ihre Zielgruppe anzusprechen.
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default Form