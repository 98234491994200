import {Slide, Zoom} from "react-awesome-reveal";
import TrackVisibility from "react-on-screen";
import styles from "./Form.module.css";
import React, {useEffect, useState} from "react";
import googleImg from "./google.png"
import starImg from "./star.png"
import user1Img from "./comment1.png"
import user2Img from "./comment2.png"
import user3Img from "./comment3.png"
import welleGrauBottom from "./welleGrauBottom.svg"

function Form(props) {

    const [time1, setTime1] = useState();
    const [time2, setTime2] = useState(0);
    const [time3, setTime3] = useState(0);
    const [comment1, setComment1] = useState('Sehr gute Leistung in jeglicher Hinsicht. Super Support und Kundenzufriedenheit steht im Fokus. Absolut Prädikat: WEITEREMPFEHLUNG');
    const [comment2, setComment2] = useState('Wir sind verblüfft, wie gut das Team von Venmedia unsere Dienstleistung beschrieben und diese dargestellt hat. Bei einem Erklärvideo kann man sehr vieles falsch machen, was hier jedoch absolut nicht der Fall war. Es ist so gut geworden, dass wir keine Verbesserungsvorschläge haben.');
    const [comment3, setComment3] = useState('Echt tolles kompetentes Team super Arbeit und schnelle Umsetzung alle welchen das Video schon gesehen haben sind begeistert. Nochmals herzlichen Dank.');

    const VisibilityTracker = (visibilityProps) => {
        if(visibilityProps.isVisible) {
            if(time1 == null) {
                setTime1(0)
            }
            return visibilityProps.children
        } else {
            return (
                <div>

                </div>);
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if(time1 != null){
                setTime1(time1+1);
                if(time1 > comment1.length) {
                    setTime2(time2+1);
                }
                if(time2 > comment2.length) {
                    setTime3(time3+1);
                }
            }
        }, 8)

        return () => {
            clearInterval(interval);
        }
    }, [time1, time2, time3])


    return (
        <div>
            <div style={{background: '#F0F0F0'}}>
                <div className="wrapper">

                    <div ref={props.childRef}>
                        <TrackVisibility>
                            <VisibilityTracker>
                                <span/>
                            </VisibilityTracker>
                        </TrackVisibility>
                        <Slide delay="20" triggerOnce>
                            <div className="venmediaHeader" style={{paddingBottom: 20}}>Das sagen unsere Kunden über uns</div>
                        </Slide>

                        <div className="grid" style={{padding: 10}}>
                            <div className={"lg:col-4 col-12 " + styles.item}>
                                <div>
                                    <Zoom triggerOnce>
                                        <div>

                                            <table style={{width: '100%'}}>
                                                <tbody>
                                                <tr>
                                                    <td style={{width: '60px'}}><img src={user1Img} className={styles.userIcon}/></td>
                                                    <td>
                                                        <div style={{fontWeight: 700}}>Smile Autovermiertung</div>
                                                    </td>
                                                    <td style={{verticalAlign: 'bottom', textAlign: 'right', width: '77px'}}>
                                                        <img src={googleImg} className={styles.googleIcon}/>
                                                        <img src={starImg} className={styles.starIcon}/>
                                                        <img src={starImg} className={styles.starIcon}/>
                                                        <img src={starImg} className={styles.starIcon}/>
                                                        <img src={starImg} className={styles.starIcon}/>
                                                        <img src={starImg} className={styles.starIcon}/>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            <div style={{padding: 10}}>
                                                <div style={{position: 'absolute'}}>{comment1.substring(0, time1)}</div>
                                                <div style={{color: 'rgba(255,255,255,0)'}}>{comment1}</div>
                                            </div>
                                        </div>
                                    </Zoom>
                                </div>
                            </div>
                            <div className={"lg:col-4 col-12 " + styles.item}>
                                <div>
                                    <Zoom triggerOnce>
                                        <div>
                                            <table style={{width: '100%'}}>
                                                <tbody>
                                                <tr>
                                                    <td style={{width: '60px'}}><img src={user2Img} className={styles.userIcon}/></td>
                                                    <td>
                                                        <div style={{fontWeight: 700}}>Karatronics Personalvermittlung</div>
                                                    </td>
                                                    <td style={{verticalAlign: 'bottom', textAlign: 'right', width: '77px'}}>
                                                        <img src={googleImg} className={styles.googleIcon}/>
                                                        <img src={starImg} className={styles.starIcon}/>
                                                        <img src={starImg} className={styles.starIcon}/>
                                                        <img src={starImg} className={styles.starIcon}/>
                                                        <img src={starImg} className={styles.starIcon}/>
                                                        <img src={starImg} className={styles.starIcon}/>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>

                                            <div style={{padding: 10}}>
                                                <div style={{position: 'absolute'}}>{comment2.substring(0, time2)}</div>
                                                <div style={{color: 'rgba(255,255,255,0)'}}>{comment2}</div>
                                            </div>
                                        </div>
                                    </Zoom>
                                </div>
                            </div>
                            <div className={"lg:col-4 col-12 " + styles.item}>
                                <div>
                                    <Zoom triggerOnce>
                                        <div>
                                            <table style={{width: '100%'}}>
                                                <tbody>
                                                <tr>
                                                    <td style={{width: '60px'}}><img src={user3Img} className={styles.userIcon}/></td>
                                                    <td>
                                                        <div style={{fontWeight: 700}}>RK Sachwert</div>
                                                    </td>
                                                    <td style={{verticalAlign: 'bottom', textAlign: 'right', width: '77px'}}>
                                                        <img src={googleImg} className={styles.googleIcon}/>
                                                        <img src={starImg} className={styles.starIcon}/>
                                                        <img src={starImg} className={styles.starIcon}/>
                                                        <img src={starImg} className={styles.starIcon}/>
                                                        <img src={starImg} className={styles.starIcon}/>
                                                        <img src={starImg} className={styles.starIcon}/>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            <div style={{padding: 10}}>
                                                <div style={{position: 'absolute'}}>{comment3.substring(0, time3)}</div>
                                                <div style={{color: 'rgba(255,255,255,0)'}}>{comment3}</div>
                                            </div>
                                        </div>
                                    </Zoom>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            <img src={welleGrauBottom} style={{width: '100%', marginBottom: -7}}/>
        </div>

    )
}
export default Form