import imgPKVBright from "./previews/PKVBright.png";
import imgPKVDark from "./previews/PKVDark.png";
import imgGemeindeGraefelfingBright from "./previews/gemeindeGraefelfingBright.png";
import imgGemeindeGraefelfingDark from "./previews/gemeindeGraefelfingDark.png";
import imgDatexBright from "./previews/DatexBright.png";
import imgDatexDark from "./previews/DatexDark.png";
import imgPumpkinBright from "./previews/PumpkinBright.png";
import imgPumpkinDark from "./previews/PumpkinDark.png";
import imgInpettoBright from "./previews/InpettoBright.png";
import imgInpettoDark from "./previews/InpettoDark.png";
import imgCheckInnDark from "./previews/CheckInnDark.png";
import imgCheckInnBright from "./previews/CheckInnBright.png";
import imgCheckInnKioskDark from "./previews/CheckInnKioskDark.png";
import imgCheckInnKioskBright from "./previews/CheckInnKioskBright.png";
import imgZufriedeneHautDark from "./previews/ZufriedeneHautDark.png";
import imgZufriedeneHautBright from "./previews/ZufriedeneHautBright.png";
import imgDsrfBright from "./previews/DsrfBright.png";
import imgDsrfDark from "./previews/DsrfDark.png";
import imgRomotoBright from "./previews/RomotoBright.png";
import imgRomotoDark from "./previews/RomotoDark.png";
import imgFenstermaxBright from "./previews/FenstermaxBright.png";
import imgFenstermaxDark from "./previews/FenstermaxDark.png";
import imgSkaylinkBright from "./previews/SkaylinkBright.png";
import imgSkaylinkDark from "./previews/SkaylinkDark.png";


import mehrAnzeigenImg from "./mehrAnzeigen.png";
import wenigerAnzeigenImg from "./wenigerAnzeigen.png";
import {Bounce, Slide} from "react-awesome-reveal";
import styles from "./Form.module.css";
import React, {useState} from "react";
import EEFadeIn from "../ui/eefadein/EEFadeIn";
import welleImg from "../area6/welleTop.svg";

function Form(props) {


    const [showMore, setShowMore] = useState(props.showMore ? props.showMore : false);
    const [selectedVideoIndex, setSelectedVideoIndex] = useState();
    const [videoMetas, setVideo1Metas] = useState(
        [
            {},
            {
                label: 'Löwenherz Consulting',
                posterBright: imgPKVBright,
                postelDark: imgPKVDark,
                src: '/refvideos/PKV.mp4'
            },
            {
                label: 'Gemeinde Gräfelfing',
                posterBright: imgGemeindeGraefelfingBright,
                postelDark: imgGemeindeGraefelfingDark,
                src: '/refvideos/FINAL_Gemeinde%20Graefelfing.mp4'
            },
            {
                label: 'Datext iT-Beratung',
                posterBright: imgDatexBright,
                postelDark: imgDatexDark,
                src: '/refvideos/FINAL_DATEXT iT-Beratung GmbH.mp4'
            },

            {
                label: 'Pumpkin Careers',
                posterBright: imgPumpkinBright,
                postelDark: imgPumpkinDark,
                src: '/refvideos/Final_pumpkincareers GmbH.mp4'
            },
            {
                label: 'Inpetto',
                posterBright: imgInpettoBright,
                postelDark: imgInpettoDark,
                src: '/refvideos/Inpetto.mp4'
            },
            {
                label: 'Digitaler Check',
                posterBright: imgCheckInnBright,
                postelDark: imgCheckInnDark,
                src: '/refvideos/Final_Digitaler_Check_Inn.mp4'
            },
            {
                label: 'Digitaler Check Inn Kiosk',
                posterBright: imgCheckInnKioskBright,
                postelDark: imgCheckInnKioskDark,
                src: '/refvideos/Final_Digitaler_Check_Inn_Kiosk.mp4'
            },

            {
                label: 'Zufriedene Haut',
                posterBright: imgZufriedeneHautBright,
                postelDark: imgZufriedeneHautDark,
                src: '/refvideos/Final_Zufriedene_Haut_Video.mp4'
            },
            {
                label: 'DRSF',
                posterBright: imgDsrfBright,
                postelDark: imgDsrfDark,
                src: '/refvideos/K46208 - 21651_DSRF_EV1_KS1.mp4'
            },
            {
                label: 'Romoto',
                posterBright: imgRomotoBright,
                postelDark: imgRomotoDark,
                src: '/refvideos/K45132_55744_WERBESPOT_ROMOTO_KS3.mp4'
            },

            {
                label: 'Fenstermaxx24',
                posterBright: imgFenstermaxBright,
                postelDark: imgFenstermaxDark,
                src: '/refvideos/K46116 - 478711 - Fenstermaxx24_EV_01.mp4'
            },
            {
                label: 'Skaylink',
                posterBright: imgSkaylinkBright,
                postelDark: imgSkaylinkDark,
                src: '/refvideos/K46188 - 79602_CSC_EV1_KS3.mp4'
            }
        ]
    );

    const VIDEO = (props) => (
        <div className={styles.col}>
            {selectedVideoIndex != props.index && (
                <img
                    style={{
                        cursor: 'pointer', width: '100%', borderTopLeftRadius: 30, borderTopRightRadius: 30,
                        boxShadow: '0px 14px 20px 5px rgba(0, 0, 0, 0.19)'
                    }}
                    src={props.meta.posterBright}
                    onMouseOver={e => (e.currentTarget.src = props.meta.postelDark)}
                    onMouseLeave={e => (e.currentTarget.src = props.meta.posterBright)}
                    onClick={() => setSelectedVideoIndex(props.index)}
                />
            )}

            {selectedVideoIndex == props.index && (
                <video
                    style={{width: '100%', borderTopLeftRadius: 30, borderTopRightRadius: 30}}
                    autoPlay
                    controls
                    preload="auto"
                    loop
                    width="100%"
                    src={props.meta.src}/>
            )}
            <div style={{background: '#FFFFFF'}}>
                <div className="venmediaHeader3">{props.meta.label}</div>
            </div>
        </div>
    )

    return (
        <div style={{background: 'rgba(255,255,255,0.8)'}}>
            <div className="wrapper">
                <div ref={props.childRef}>
                    <Slide delay="20" triggerOnce>
                        <center>
                            <div className="venmediaHeader" style={{paddingBottom: 60}}>Was haben wir bisher gemacht?
                            </div>
                        </center>
                    </Slide>

                    <table style={{width: '100%'}}>
                        <tbody>
                        <tr>
                            <td style={{width: '33%'}}>
                                <EEFadeIn orientation="left">
                                    <VIDEO index={1} meta={videoMetas[1]} />
                                </EEFadeIn>
                            </td>
                            <td style={{width: '33%'}}>
                                <EEFadeIn orientation="top">
                                    <VIDEO index={2} meta={videoMetas[2]} />
                                </EEFadeIn>
                            </td>
                            <td style={{width: '33%'}}>
                                <EEFadeIn orientation="right">
                                    <VIDEO index={3} meta={videoMetas[3]} />
                                </EEFadeIn>
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: '33%'}}>
                                <EEFadeIn orientation="left">
                                    <VIDEO index={4} meta={videoMetas[4]} />
                                </EEFadeIn>
                            </td>
                            <td style={{width: '33%'}}>
                                <EEFadeIn orientation="top">
                                    <VIDEO index={5} meta={videoMetas[5]} />
                                </EEFadeIn>
                            </td>
                            <td style={{width: '33%'}}>
                                <EEFadeIn orientation="right">
                                    <VIDEO index={6} meta={videoMetas[6]} />
                                </EEFadeIn>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    {showMore && (
                        <table style={{width: '100%'}}>
                            <tbody>
                            <tr>
                                <td style={{width: '33%'}}>
                                    <EEFadeIn orientation="left">
                                        <VIDEO index={7} meta={videoMetas[7]}/>
                                    </EEFadeIn>
                                </td>
                                <td style={{width: '33%'}}>
                                    <EEFadeIn orientation="top">
                                        <VIDEO index={8} meta={videoMetas[8]}/>
                                    </EEFadeIn>
                                </td>
                                <td style={{width: '33%'}}>
                                    <EEFadeIn orientation="right">
                                        <VIDEO index={9} meta={videoMetas[9]}/>
                                    </EEFadeIn>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    )}

                    {showMore && (
                        <table style={{width: '100%'}}>
                            <tbody>
                            <tr>
                                <td style={{width: '33%'}}>
                                    <EEFadeIn orientation="left">
                                        <VIDEO index={10} meta={videoMetas[10]}/>
                                    </EEFadeIn>
                                </td>
                                <td style={{width: '33%'}}>
                                    <EEFadeIn orientation="top">
                                        <VIDEO index={11} meta={videoMetas[11]}/>
                                    </EEFadeIn>
                                </td>
                                <td style={{width: '33%'}}>
                                    <EEFadeIn orientation="right">
                                        <VIDEO index={12} meta={videoMetas[12]}/>
                                    </EEFadeIn>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    )}

                    {showMore == false && (
                        <div style={{textAlign: 'center'}}>
                            <div style={{cursor: 'pointer'}} onClick={() => setShowMore(true)}>
                                <div style={{fontSize: 24, color: 'blue'}}>
                                    <img className={styles.blink_text} src={mehrAnzeigenImg} style={{width: '40px'}}/>
                                </div>
                            </div>
                        </div>
                    )}

                    {showMore && (
                        <div style={{textAlign: 'center'}}>
                            <div style={{cursor: 'pointer'}} onClick={() => setShowMore(false)}>
                                <div style={{fontSize: 24, color: 'blue'}}>
                                    <img className={styles.blink_text} src={wenigerAnzeigenImg}
                                         style={{width: '40px'}}/>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Form