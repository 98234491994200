import verkaufImg from "./verkauf.png";
import marketingImg from "./marketing.png";
import conversionRateImg from "./conversionRate.png";
import seoImg from "./seo.png";
import wirkungImg from "./wirkung.png";
import {Bounce, Slide} from "react-awesome-reveal";
import CountUp from "react-countup";
import {Carousel} from "primereact/carousel";
import {useState} from "react";
import {Button} from "primereact/button";

function Form(props) {

    const [page, setPage] = useState(0);

    const VisibilityTracker = (visibilityProps) => {
        if(visibilityProps.isVisible) {
            return visibilityProps.children
        } else {
            return (
                <div>
                    {visibilityProps.children}
                </div>);
        }
    }

    const charts =[
        {
            text: "87% der Marketingexperten empfehlen Videos als Marketinginstrument.",
            label: "Marketing",
            number: 87,
            subfix: "%",

        },
        {
            text: "Acht von 10 Personen, die eine Software oder Anwendung gekauft haben, geben an, dass sie zuvor Videos dazu angeschaut haben.",
            label: "Verkauf",
            number: 80,
            subfix: "%",

        },
        {
            text: "Circa 80% der Kunden ziehen ein Erklärvideo textlichem Content vor, denn bewegte Bilder erzeugen viele Emotionen und unterhalten.",
            label: "Conversion Rate",
            number: 80,
            subfix: "%",

        },
        {
            text: "Um 88% steigt die Verweildauer eines Besuchers auf Ihrer Homepage mit einem Erklärvideo. Außerdem optimiert sich Ihr Ranking bei Google und anderen Suchmaschinen automatisch.",
            label: "SEO & Verweildauer",
            number: 88,
            subfix: "%",

        },
        {
            text: "Um 95% erfassen Kunden Videoinhalte besser als anderen Content und sogar 60.000 Mal schneller. Mit einem Erklärvideo bleibt Ihr Produkt oder Ihre Dienstleistung im Kopf.",
            label: "Wirkung",
            number: 95,
            subfix: "%",

        }]


    const chartCarouselTemplate =(chart) => {
        return (
            <div>
                <div className="venmediaHeader3">{chart.label}</div>
                <div style={{paddingTop: 20}}>{chart.text}</div>
                <center>
                    <div style={{color: '#FF0073', fontWeight: 600, fontSize: 60}}><CountUp end={chart.number} duration={1} redraw={true}/>{chart.subfix}</div>
                </center>
            </div>
        );
    }

    return (
        <div style={{background: 'rgba(255,255,255,0.8)'}}>
            <div className="wrapper">
                <div ref={props.childRef}>
                    <Slide delay="20" triggerOnce>
                        <div className="venmediaHeader">Statistiken</div>
                    </Slide>
                    <div className="grid">
                        <div className="lg:col-6 col-12">
                            <div style={{paddingTop: 60}}>
                                <Bounce triggerOnce>
                                    <Carousel
                                        page={page}
                                        onPageChange={(event) => setPage(event.page)}
                                        value={charts}
                                        numVisible={1}
                                        numScroll={1}
                                        footer={(<div style={{marginTop: -45}}>
                                            {page > 0 && (<Button style={{float: 'left', background: 'var(--venmediaColor1)'}} icon="pi pi-chevron-left" onClick={() => setPage(page - 1)}/>)}
                                            {page < 4 && (<Button style={{float: 'right', background: 'var(--venmediaColor1)'}} icon="pi pi-chevron-right" onClick={() => setPage(page +1)}/>)}
                                        </div>)}

                                        itemTemplate={(item) => chartCarouselTemplate(item)}/>
                                </Bounce>
                            </div>
                        </div>
                        <div className="lg:col-6 col-12">
                            <div style={{height: '410px'}}>

                                {page == "0" && (
                                    <Bounce triggerOnce>
                                        <center>
                                            <img src={verkaufImg} style={{maxHeight: '400px', maxWidth: '100%'}}/>
                                        </center>
                                    </Bounce>
                                )}
                                {page == "1" && (
                                    <Bounce triggerOnce>
                                        <center>
                                            <img src={marketingImg} style={{maxHeight: '400px', maxWidth: '100%'}}/>
                                        </center>
                                    </Bounce>
                                )}
                                {page == "2" && (
                                    <Bounce triggerOnce>
                                        <center>
                                            <img src={conversionRateImg} style={{maxHeight: '400px', maxWidth: '100%'}}/>
                                        </center>
                                    </Bounce>
                                )}
                                {page == "3" && (
                                    <Bounce triggerOnce>
                                        <center>
                                            <img src={seoImg} style={{maxHeight: '400px', maxWidth: '100%'}}/>
                                        </center>
                                    </Bounce>
                                )}
                                {page == "4" && (
                                    <Bounce triggerOnce>
                                        <center>
                                            <img src={wirkungImg} style={{maxHeight: '400px', maxWidth: '100%'}}/>
                                        </center>
                                    </Bounce>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Form