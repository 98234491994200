import styles from "./Form.module.css";
import React from "react";
import individuellAnpassbarImg from "./IndividuellAnpassbar.png";
import ciImg from "./CI.png";
import {Bounce} from "react-awesome-reveal";
import image from "./ampel.png";

function Form(props) {

    return (
        <React.Fragment>
            <div  style={{background: 'var(--venmediaColor2)'}}>
                <div className="wrapper" style={{paddingTop: 0, paddingBottom: 0}}>
                    <div style={{padding: 40}}>
                        <div className="grid">
                            <div className={"lg:col-4 col-12 " + styles.itemPlaceholder}>
                            </div>
                            <div className={"lg:col-6 col-12 " + styles.item}>
                                <Bounce triggerOnce>
                                    <div className="grid">
                                        <div className="lg:col-12 col-4" style={{textAlign: 'center', height: '160px'}}><img src={individuellAnpassbarImg} style={{width: '180px', maxWidth: '100%', paddingTop: 15}}/></div>
                                        <div className={"lg:col-12 col-8"}>
                                            <div className={"venmediaHeader3 " + styles.itemHeader}>Individuell Anpassbar</div>
                                            <div className={styles.itemContent}>
                                                Welche Anforderung Sie auch haben, wir haben das Knowhow und die Technik, diese zu erfüllen. Mit moderner Software animieren wir Gebäude und Geräte als hochwertige Alternative zu den gängigen Screenshots.
                                            </div>
                                        </div>
                                    </div>
                                </Bounce>
                            </div>
                            <div className={styles.item}>
                                <Bounce delay="100" triggerOnce>
                                    <div className="grid">
                                        <div className="lg:col-12 col-4" style={{textAlign: 'center', height: '160px'}}><img src={ciImg} style={{width: '180px', maxWidth: '100%', paddingTop: 15}}/></div>
                                        <div className="lg:col-12 col-8">
                                            <div className={"venmediaHeader3 " + styles.itemHeader}>Corporate Identity (CI)<br/></div>
                                            <div className={styles.itemContent}>
                                                Damit Ihr Erklärvideo ideal zum Gesicht Ihres Unternehmens passt, beziehen wir Ihre Farbvorgaben und Gestaltungswünsche mit ein. Dazu gehört der angemessene Einsatz Ihres Logos genauso wie der Farbcode, der Ihr Unternehmen ausmacht.
                                            </div>
                                        </div>
                                    </div>
                                </Bounce>
                            </div>
                            <div className={"lg:col-4 col-12 " + styles.itemPlaceholder}>
                            </div>
                        </div>
                    </div>


                </div>

            </div>
            <div  style={{background: 'linear-gradient(180deg, rgba(1,217,151,1) 0%, rgba(240,240,240,1) 35%)'}}>
                <div className="wrapper">

                    <div className={styles.bottomItem}>
                        <div className="grid">
                            <div className="col-3">
                                <img src={image} style={{width: '100%'}}/>
                            </div>
                            <div className="col-9">
                                <div className="venmediaHeader">Vertriebssicher!</div>
                                <div>Mit den von Ihnen erhaltenen Informationen erstellen wir ein Funnel und ein zielgerichtetes, individuelles und vertriebssicheres Konzept. Einfacher gesagt, wir verwandeln unverbindliches Interesse in echte Kundschaft, indem wir die Menschen fesseln. Farben und Schlüsselszenen im richtigen Augenblick treffen die Emotionen. Sätze, Betonungen, Farben und Bilder heben die Vorzüge Ihrer Produkte und Dienstleistungen hervor und schaffen ein enges Band zum Publikum. Unsere Fachleute aus den Bereichen Grafik, Motion Design, Texterstellung, Voice Over und Projektmanagement machen das VENmedia Team zu einem Partner, der den Wert Ihres Angebotes klar beim Namen nennt. Ein Video von VENmedia ist mehr als ein Erklärvideo, es ist ein Verkaufsgespräch. </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default Form