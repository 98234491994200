import {Bounce, Slide} from "react-awesome-reveal";
import CountUp from "react-countup";
import styles from "./Form.module.css";
import React from "react";
import projekteImg from "./Projekte.png"
import expertenImg from "./Experten.png"
import kundenImg from "./Kunden.png"
import TeamAnimation from "../team/animation/Form";

function Form(props) {

    return (
        <div style={{background: '#333333', color: '#FFFFFF', position: 'relative'}}>
            <div style={{height: 600, position: 'absolute'}}>
                <div style={{zIndex: 0}}>
                    <div style={{borderRadius: 150, marginTop: 75, marginLeft: '-15vw', height: '20vw', width: '20vw', background: '#4D4D4D'}}/>
                </div>
                <div style={{}}>
                    <div style={{borderRadius: 150, marginTop: 50, marginLeft: '20vw', height: '3vw', width: '3vw', background: '#4D4D4D'}}/>
                </div>
            </div>
            <div className="wrapper" style={{paddingBottom: 0}}>
                <div ref={props.childRef}>
                    <Slide delay="20" triggerOnce>
                        <div className="venmediaHeader" style={{textAlign: 'center', paddingBottom: 40}}>Venmedia in Zahlen</div>
                    </Slide>

                    <div className="grid">
                        <div className="col-4">
                            <Bounce delay={0} triggerOnce>
                                <div className={styles.item}>
                                    <img src={projekteImg} style={{width: '25%'}}/>
                                    <div style={{color: 'var(--venmediaColor2)', fontWeight: 600, fontSize: '4vw'}}><CountUp end={1500} duration={1} separator="."/>+</div>
                                    <div>Projekte</div>
                                </div>
                            </Bounce>
                        </div>
                        <div className="col-4">
                            <Bounce delay={500} triggerOnce>
                                <div className={styles.item}>
                                    <img src={expertenImg} style={{width: '25%'}}/>
                                    <div style={{color: 'var(--venmediaColor2)', fontWeight: 600, fontSize: '4vw'}}><CountUp end={8} duration={2} separator="."/></div>
                                    <div>Experten</div>
                                </div>
                            </Bounce>
                        </div>
                        <div className="col-4">
                            <Bounce delay={1000} triggerOnce>
                                <div className={styles.item}>
                                    <img src={kundenImg} style={{width: '25%'}}/>
                                    <div style={{color: 'var(--venmediaColor2)', fontWeight: 600, fontSize: '4vw'}}><CountUp end={1000} duration={3} separator="."/>+</div>
                                    <div>Kunden</div>
                                </div>
                            </Bounce>
                        </div>
                    </div>
                </div>

                <TeamAnimation/>
            </div>
        </div>
    )
}
export default Form