import styles from './Form.module.css';
import Faq from "./../ui/faq/Form"
import React, {useState} from "react";
import PlanetAnimation from "../ui/eeplanetanimation/Form";
import {Slide} from "react-awesome-reveal";
import faqImg from "./Faqs.png";
import individuellAnpassbarImg from "../area9/IndividuellAnpassbar.png";

function Form(props) {

    return (
        <div style={{background: 'var(--venmediaColor2)'}}>
            <PlanetAnimation backgroundColor="#6362EC"/>
            <div className="wrapper">
                <div ref={props.childRef} style={{marginTop: -40}}>
                    <Slide delay="20" triggerOnce>
                        <div className="venmediaHeader" style={{textAlign: 'center'}}>Häufig gestellte Fragen</div>
                    </Slide>
                    <div className="grid">
                        <div className="lg:col-6 p-d-none p-d-lg-inline-flex">
                            <div style={{textAlign: 'center', width: '100%'}}>
                                <img src={faqImg} style={{width: '100%', paddingTop: 120}}/>
                            </div>
                        </div>
                        <div className="lg:col-6 col-12">
                            <div style={{paddingTop: 120}}>
                                <Faq
                                    items={[
                                        {
                                            question: "Was bedeutet 100%ige Kundenzufriedenheit?",
                                            answer: "Sie treffen alle Entscheidungen nach Ihren individuellen Wünschen. Währenddessen betrachten wir Ihr Produkt oder Ihre Dienstleistung in der Vogelperspektive und beraten Sie zum Stil, zum Design, zur Sprecherstimme und zu allen anderen Merkmalen. Im Rahmen der Produktion werden auch die Texte erstellt. Diese korrigieren wir so lange, bis Sie mit der endgültigen Version zufrieden sind. Sobald Sie uns bestätigt haben, dass die Ausarbeitung des Videokonzeptes Ihre Wünsche erfüllt, stellen wir das Erklärvideo fertig. Auch jetzt nehmen wir Änderungswünsche über unseren Korrekturbogen noch gerne an. Und zwar so oft, bis das Erklärvideo zu 100% Ihren Vorstellungen entspricht. "
                                        },
                                        {
                                            question: "Warum eigentlich ein Erklärvideo?",
                                            answer: "Kurze animierte Videos sind das ideale Sprachrohr, um Ihre Zielgruppe mit Informationen zu versorgen. Zum einen macht das lebendige Bild- und Tonformat die Inhalte aufnahmefähiger. Das alleinige Lesen komplizierter Textinhalte dagegen ist langweilig und kostet viel mehr Zeit, die nur wenige aufbringen. Und hier liegt auch schon ein weiterer großer Vorteil: Ein Erklärvideo bietet Ihrem Unternehmen die ideale Möglichkeit, komplexe Sachverhalte in kurzer Zeit verständlich darzustellen – auf Firmen-Webseiten und auf den großen Social Media Plattformen. Sie erreichen auf diese Weise nicht nur ein größeres Publikum, sondern kommen mit Ihren Followern interaktiv ins Gespräch. Denn das Kurzformat verlockt durchaus zum Liken und Kommentieren. "
                                        },
                                        {
                                            question: "Was ist mein Beitrag zur Realisierung?",
                                            answer: "Die Vorbereitungen und den Produktionsprozess halten wir für Sie so einfach wie möglich. Sie füllen lediglich einen Fragebogen aus und teilen mit uns Textdateien und Präsentationen, die für Sie wichtig sind. Falls Sie Unterstützung benötigen, stehen Ihnen unsere Fachleute gerne telefonisch zur Seite."
                                        },
                                        {
                                            question: "Erhalte ich alle Nutzungsrechte an dem Erklärvideo?",
                                            answer: "Ja, Sie erhalten die vollständigen Nutzungsrechte. Nur die Rechte an Rohdaten, das sind einzelne Elemente des Videos, können wir aus technischen Gründen leider nicht weitergeben. Möglich ist allerdings die Speicherung dieser Daten bei uns. So können wir Ihr Video anpassen, wenn sich Ihr Produkt oder Ihre Dienstleistung später weiterentwickelt. "
                                        },
                                        {
                                            question: "Wie lange dauert die Produktion?",
                                            answer: "Sobald Sie uns alle notwendigen Informationen zugesandt haben, fertigen wir Ihr Erklärvideo innerhalb von 12 Werktagen. Sie haben Änderungswünsche? Innerhalb von maximal 2 Werktagen versenden wir das optimierte Video erneut an Sie."
                                        },
                                        {
                                            question: "Bieten Sie auch Erklärvideos in anderen Sprachen an?",
                                            answer: "Unser Team erstellt Erklärvideos in deutsch und englisch genauso wie in anderen Sprachen. Kommen Sie einfach mit Ihrer Wunschsprache auf uns zu."
                                        },
                                        {
                                            question: "Welches Dateiformat hat mein Erklärvideo?",
                                            answer: "Wir blicken für Sie weit über das übliche MPEG4-Format hinaus und stellen das Erklärvideo auf Wunsch ohne Aufpreis in Ihrem Wunschformat zur Verfügung. Für Social Media beispielsweise erstellen wir Display-Videos in Formaten, die auf die jeweilige Plattform (Instagram etc.) zugeschnitten sind. Hier erfahren Sie mehr über unser Social Media Paket. "
                                        }
                                    ]}
                                />
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>

    )
}
export default Form