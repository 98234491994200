import styles from './Form.module.css';
import {useState} from "react";

function Form(props) {

    const [selectedIndex, setSelectedIndex] = useState();

    return (
        <div>
            {props.items.map( (item, index) => (
                <div className={styles.item} onClick={() => index == selectedIndex ? setSelectedIndex(-1) : setSelectedIndex(index)}>
                    <table>
                        <tbody>
                        <tr >
                            <td style={{width: '100%'}}>{item.question}</td>
                            <td>{selectedIndex == index ? '-' : '+'}</td>
                        </tr>
                        </tbody>
                    </table>
                    {selectedIndex == index && (
                        <div className={styles.itemContent}>
                            {item.answer}
                        </div>
                    )}
                </div>
            ))}
        </div>

    )
}
export default Form