import {Bounce, Slide, Zoom} from "react-awesome-reveal";
import styles from "./Form.module.css";
import PlanetAnimation from "./../ui/eeplanetanimation/Form"
import {InputText} from "primereact/inputtext";
import React, {useState} from "react";
import Faq from "../ui/faq/Form";
import {AppApi} from "../AppApi";
import welleImg from "../area6/welleTop.svg";
import welleBottomImg from "../area6/welleBottom.svg";

function Form(props) {


    const [isFormPost, setIsFormPost] = useState(false);
    const [isNameEmpty, setIsNameEmpty] = useState(false);
    const [isEmailEmpty, setIsEmailEmpty] = useState(false);
    const [isTelephoneEmpty, setIsTelephoneEmpty] = useState(false);

    const [form, setForm] = useState({
        name: null,
        email: null,
        telephone: null
    });

    const actions = {
        post: () => {
            let sendEmail = true
            if(form.name == null) {
                setIsNameEmpty(true)
                sendEmail = false
            } else {
                setIsNameEmpty(false)
            }

            if( form.email == null || !form.email.includes("@") || !form.email.includes(".")) {
                setIsEmailEmpty(true)
                sendEmail = false
            } else {
                if(
                    form.email.toLowerCase().includes("@web")
                    || form.email.toLowerCase().includes("@icloud")
                    || form.email.toLowerCase().includes("@gmx")
                    || form.email.toLowerCase().includes("@hotmail")
                    || form.email.toLowerCase().includes("@outlook")
                    || form.email.toLowerCase().includes("@googlemail")
                    || form.email.toLowerCase().includes("@gmail")
                    || form.email.toLowerCase().includes("@freenet")
                    || form.email.toLowerCase().includes("@t-online.de")
                    || form.email.toLowerCase().includes("@mnet-mail.")
                    || form.email.toLowerCase().includes("@yahoo")
                ) {
                    alert("Bitte verwenden Sie für geschäftliche Anfragen Ihre Geschäfts-Mailadresse anstelle Ihrer privaten E-Mail.\n" +
                        "Falls Sie persönliche Anliegen haben, möchten wir Sie bitten, uns unter der Rufnummer 06171 951 80 86 anzurufen, damit wir Ihnen sofort eine Beratung anbieten können.")
                    setIsEmailEmpty(true)
                    sendEmail = false
                } else {
                    setIsEmailEmpty(false)
                }
            }

            if(form.telephone == null) {
                setIsTelephoneEmpty(true)
                sendEmail = false
            } else {
                setIsTelephoneEmpty(false)
            }

            if(sendEmail) {
                let postForm = {
                    token: '249b8775-15c8-4520-827d-668fa7c094ca',
                    name: form.name,
                    email: form.email,
                    telephone: form.telephone
                }
                AppApi.pub.form.post(postForm)
                    .then(response => {
                        window.location.href = "/danke/jetztKontaktAufnehmen"
                    })
                    .catch(console.log)
            }

        },
    }

    return (
        <div>

            <div style={{background: 'rgba(255,255,255,0.8)'}}>
                <img src={welleImg} style={{width: '100%', marginBottom: -7}}/>
            </div>
            <div style={{background: 'var(--venmediaColor2)'}}>

                <div style={{position: 'absolute'}}>
                    <div style={{borderRadius: 150, marginTop: 50, marginLeft: 50, height: 200, width: 200, background: '#75bcf6', boxShadow: '0px 14px 20px 5px rgba(0, 0, 0, 0.19)'}}/>
                </div>
                <div style={{position: 'absolute'}}>
                    <div style={{borderRadius: 150, marginTop: 400, marginLeft: 150, height: 30, width: 30, background: '#97bfea', boxShadow: '0px 14px 20px 5px rgba(0, 0, 0, 0.19)'}}/>
                </div>
                <div style={{position: 'absolute'}}>
                    <div style={{borderRadius: 150, marginTop: 400, marginLeft: -150, height: 200, width: 200, background: '#b3d0f3', boxShadow: '0px 14px 20px 5px rgba(0, 0, 0, 0.19)'}}/>
                </div>

                <PlanetAnimation/>
                <div className="wrapper">
                    <div ref={props.childRef}>
                        <div className="grid">
                            <div className="lg:col-6 col-12">
                                <Slide direction="up" triggerOnce>

                                    <Slide delay="20" direction="left" triggerOnce>
                                        <div className="venmediaHeader2" style={{color: 'var(--venmediaColor-2)'}}>Jetzt Kontakt aufnehmen!</div>
                                    </Slide>

                                    <div style={{paddingTop: 20}}>
                                        <Faq
                                            items={[
                                                {
                                                    question: "Warum ein Erklärvideo?",
                                                    answer:"Die Produktion passgenauer Erklärvideos ist unsere Leidenschaft. Deshalb dürfen Sie ein Konzept erwarten, das sich nach Ihren individuellen Zielen richtet. Durch den gekonnten Einsatz von Schlüsselszenen wecken wir Emotionen und steigern die Aufmerksamkeitskurve. Erklärvideos stehen für eine sehr erfolgreiche Marketing-Strategie der heutigen Zeit. Unser dynamisches Fachteam unterstützt Sie mit Expertenwissen, jahrelanger Erfahrung und einem durchdachten Konzept für ein vertriebssicheres Video, das Ihr Produkt oder Ihre Dienstleistung interessant erklärt. "
                                                },
                                                {
                                                    question: "Was passiert nach der Anfrage?",
                                                    answer:"Sie nehmen für ein Briefing Kontakt mit uns auf und wir melden uns für eine ausführliche Beratung. Bis hin zum passenden Angebot, welches wir für Ihr gewünschtes Video erstellen, ist für Sie alles kostenlos und unverbindlich. "
                                                },
                                                {
                                                    question: "Wie lange dauert die Produktion?",
                                                    answer:"Meistens gelingt die Produktion eines Erklärvideos in etwa zehn Werktagen. Maßstab ist hier immer die Effizienz in der Zusammenarbeit. Nach der Fertigstellung bieten wir Ihnen über drei Wochen hinweg die Möglichkeit unbegrenzter Korrekturschleifen. Wenn Sie bereits im Voraus unseren Fragebogen ausfüllen, entsteht für Sie eine große Zeitersparnis. Anhand der Inhalte analysieren wir die Kernaussage und erstellen ein erstes Konzept. Kurze Zeit später erhalten Sie den ersten Drehbuchentwurf zur Abstimmung. Auch hier sind Korrekturen in unbegrenzter Anzahl kostenlos. Sobald Sie Ihre Zustimmung zum Text erteilt haben, können Sie Ihre endgültige Entscheidung zu einer Stimme aus unserem Sprecherpool treffen."
                                                },
                                                {
                                                    question: "Wann muss ich bezahlen?",
                                                    answer:"Sobald wir alle etwaigen Änderungswünsche umgesetzt haben und Sie mit Ihrem Video 100%ig zufrieden sind, erhalten Sie von uns eine Rechnung."
                                                }
                                            ]}
                                        />
                                    </div>
                                </Slide>
                            </div>
                            <div className="lg:col-6 col-12">
                                <Bounce triggerOnce>
                                    <div className={styles.contactForm}>
                                        <div>

                                            <div className="venmediaHeader2">Jetzt kostenfreien Beratungstermin vereinbaren</div>

                                            <div>
                                                <div style={{paddingTop: 10, color: isNameEmpty ? 'red' : null}}>Name:</div>
                                                <InputText
                                                    className="p-inputtext-lg block"
                                                    style={{width: '100%'}}
                                                    value={form.name}
                                                    placeholder="Name"
                                                    onChange={(event) => setForm({...form, name: event.target.value})}/>

                                                <div style={{paddingTop: 10, color: isEmailEmpty ? 'red' : null}}>Email:</div>
                                                <InputText
                                                    className="p-inputtext-lg block"
                                                    style={{width: '100%'}}
                                                    value={form.email}
                                                    placeholder="Email"
                                                    onChange={(event) => setForm({...form, email: event.target.value})}/>

                                                <div style={{paddingTop: 10, color: isTelephoneEmpty ? 'red' : null}}>Telefon:</div>
                                                <InputText
                                                    className="p-inputtext-lg block"
                                                    style={{width: '100%'}}
                                                    value={form.telephone}
                                                    placeholder="Telefon"
                                                    onChange={(event) => setForm({...form, telephone: event.target.value})}/>

                                                <div style={{paddingTop: 20}}>
                                                    <Bounce delay={700}>
                                                        <center>

                                                            <div
                                                                className="venmediaButton"
                                                                style={{width: '200px'}}
                                                                onClick={() => actions.post()}>
                                                                Senden
                                                            </div>
                                                        </center>
                                                    </Bounce>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </Bounce>
                            </div>
                        </div>

                    </div>

                </div>
            </div>


            <div style={{background: 'rgba(255,255,255,0.8)'}}>
                <img src={welleBottomImg} style={{width: '100%', marginBottom: -7}}/>
            </div>
        </div>

    )
}
export default Form