import styles from ".//Form.module.css";
import img from "./20166.png";
import React from "react";

function Form(props) {

    const actions = {
        mailTo: (subject) => {
            window.location.href = "mailto:job@venmedia.de?subject=" + subject
        }
    }

    return (
        <React.Fragment>
            <div className="wrapper">
                <div className="venmediaHeader">Wir suchen Sie!</div>
                <div>
                    Sie haben Interesse in einem jungen motivierten und kreativen Team zu arbeiten? Dann sucht Venmedia nach
                    Ihnen!
                </div>
                <br/>
                <div className="venmediaHeader3">Für folgende Positionen werden gesucht:</div>
                <br/>

                <center>
                    <table cellPadding={10} style={{width: '100%', maxWidth: 600}}>
                        <tbody>
                        <tr className={styles.tableRow}>
                            <td>
                                <div className={styles.tableContentHeader}><b>Junior Drehbuchautor (m/w/d)</b></div>
                                <div className={styles.tableContent}>
                                    Sie übernehmen Erstellung und Gestaltung von Drehbüchern
                                </div>
                            </td>
                            <td className={styles.tableCellRight} onClick={() => actions.mailTo("Bewerbung Junior Drehbuchautor (m/w/d)")}>
                                Jetzt bewerben
                            </td>
                        </tr>
                        <tr className={styles.tableRow}>
                            <td>
                                <div className={styles.tableContentHeader}><b>Freelancer (m/w/d)</b></div>
                                <div className={styles.tableContent}>
                                    Sie übernehmen Zuarbeit in der Videoproduktion
                                </div>
                            </td>
                            <td className={styles.tableCellRight} onClick={() => actions.mailTo("Bewerbung Freelancer (m/w/d)")}>
                                Jetzt bewerben
                            </td>
                        </tr>
                        <tr className={styles.tableRow}>
                            <td>
                                <div className={styles.tableContentHeader}><b>Sales Assistent (m/w/d)</b></div>
                                <div className={styles.tableContent}>
                                    Assistenz für den Vertrieb
                                </div>
                            </td>
                            <td className={styles.tableCellRight} onClick={() => actions.mailTo("Bewerbung Sales Assistent (m/w/d)")}>
                                Jetzt bewerben
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </center>
            </div>
            <img src={img} style={{width: '100%'}}/>
        </React.Fragment>

    )
}

export default Form